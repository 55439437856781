export default {
  path: '/',
  component: () => import('@/layouts/LayoutContentHorizontalNav.vue'),
  meta: { protected: true },
  children: [
    {
      path: 'transport/pickup',
      name: 'ManagePickup',
      component: () => import('@/views/transport/PickupPoints.vue'),
    },
    {
      path: 'transport/routes',
      name: 'ManageRoutes',
      component: () => import('@/views/transport/TransportRoutes.vue'),
    },
    {
      path: 'transport/vehicles',
      name: 'ManageVehicles',
      component: () => import('@/views/transport/Vehicles.vue'),
    },
    {
      path: 'transport/drivers',
      name: 'ManageDrivers',
      component: () => import('@/views/transport/Drivers.vue'),
    },
    {
      path: 'transport/document',
      name: 'ManageDocument',
      component: () => import('@/views/transport/ManageDocument.vue'),
    },
    {
      path: 'transport/add-document',
      name: 'AddDocument',
      component: () => import('@/views/transport/AddDocument.vue'),
    },
    {
      path: 'transport/assignstudent',
      name: 'AssignStudent',
      component: () => import('@/views/transport/AssignStudent.vue'),
    },
    {
      path: 'transport/assignstudentAdmissionNo',
      name: 'AssignStudentAdmissionNo',
      component: () => import('@/views/transport/AssignStudentByAdmNo.vue'),
    },
    {
      path: 'transport/viewStudent',
      name: 'ViewStudent',
      component: () => import('@/views/transport/ViewStudent.vue'),
    },
    {
      path: 'transport/transferStudent',
      name: 'TransferStudent',
      component: () => import('@/views/transport/TransferStudent.vue'),
    },
    {
      path: 'transport/managestaff',
      name: 'TransportStaff',
      component: () => import('@/views/transport/ManageStaff.vue'),
    },
    {
      path: 'transport/buswisemsg',
      name: 'BusWiseMsg',
      component: () => import('@/views/transport/BusWiseMsg.vue'),
    },
    {
      path: 'transport/trackbydate',
      name: 'TrackByDate',
      component: () => import('@/views/transport/TrackByDate.vue'),
    },
    {
      path: 'transport/topspeedreport',
      name: 'TopSpeedReport',
      component: () => import('@/views/transport/TopSpeedReport.vue'),
    },
    {
      path: 'transport/distancereport',
      name: 'DistanceReport',
      component: () => import('@/views/transport/DistanceReport.vue'),
    },
    {
      path: 'transport/fuelreport',
      name: 'FuelReport',
      component: () => import('@/views/transport/FuelReport.vue'),
    },
  ],
}
