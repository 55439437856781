export default {
  path: '/',
  component: () => import('@/layouts/LayoutContentHorizontalNav.vue'),
  meta: { protected: true },
  children: [
    {
      path: 'examinations/categories',
      name: 'CategoryList',
      component: () => import('@/views/examinations/exam-setting/CategoryList.vue'),
    },
    {
      path: 'examinations/terms',
      name: 'TermsList',
      component: () => import('@/views/examinations/exam-setting/TermsList.vue'),
    },
    {
      path: 'examinations/exams',
      name: 'ExamsList',
      component: () => import('@/views/examinations/exam-setting/ExamsList.vue'),
    },
    {
      path: 'examinations/activities',
      name: 'ActivitiesList',
      component: () => import('@/views/examinations/exam-setting/ActivitiesList.vue'),
    },
    {
      path: 'examinations/parent-skills',
      name: 'ParentSkillsList',
      component: () => import('@/views/examinations/exam-setting/ParentSkillsList.vue'),
    },
    {
      path: 'examinations/child-skills',
      name: 'ChildSkillsList',
      component: () => import('@/views/examinations/exam-setting/ChildSkillsList.vue'),
    },
    {
      path: 'examinations/descriptive-indicators',
      name: 'DescriptiveIndicatorList',
      component: () => import('@/views/examinations/exam-setting/DescriptiveIndicatorList.vue'),
    },

    // exam marks reports
    {
      path: 'examinations/co-scholastic-grade',
      name: 'CoScholasticGradeList',
      component: () => import('@/views/examinations/marks-entry/CoScholasticGradeList.vue'),
    },
    {
      path: 'examinations/scholastic-grade',
      name: 'ScholasticGradeList',
      component: () => import('@/views/examinations/marks-entry/ScholasticGradeList.vue'),
    },

    {
      path: 'examinations/marks-entry',
      name: 'MarksEntryList',
      component: () => import('@/views/examinations/marks-entry/MarksEntryList.vue'),
    },
    {
      path: 'examinations/coscholastic-marks-entry',
      name: 'CoScholasticMarksEntryList',
      component: () => import('@/views/examinations/marks-entry/CoScholasticMarksEntryList.vue'),
    },
    {
      path: 'examinations/attendance-entry',
      name: 'AttendanceEntryList',
      component: () => import('@/views/examinations/marks-entry/AttendanceEntryList.vue'),
    },
    {
      path: 'examinations/coscholastic-remarks-entry',
      name: 'CoScholasticRemarksEntryList',
      component: () => import('@/views/examinations/marks-entry/CoScholasticRemarksEntryList.vue'),
    },
    {
      path: 'examinations/view-scholastic-marks',
      name: 'ViewScholasticMarks',
      component: () => import('@/views/examinations/marks-entry/ViewScholasticMarks.vue'),
    },
    {
      path: 'examinations/view-marks-entry',
      name: 'ViewMarksEntry',
      component: () => import('@/views/examinations/marks-entry/ViewMarksEntry.vue'),
    },

    // exam marks reports
    {
      path: 'examinations/low-marks-report',
      name: 'ViewLowMarks',
      component: () => import('@/views/examinations/ViewLowMarks.vue'),
    },
    {
      path: 'examinations/rangewise-report',
      name: 'RangewiseReport',
      component: () => import('@/views/examinations/RangewiseReport.vue'),
    },
    {
      path: 'examinations/term-report',
      name: 'TermReport',
      component: () => import('@/views/examinations/TermReport.vue'),
    },
    {
      path: 'examinations/report-card',
      name: 'reportCard',
      component: () => import('@/views/examinations/ReportCard.vue'),
    },
    {
      path: 'examinations/report-card-by-class',
      name: 'reportCardByClass',
      component: () => import('@/views/examinations/ReportCardByClass.vue'),
    },
    {
      path: 'examinations/reportcard-print-by-student/:studentId',
      name: 'reportCardPrintByStudentId',
      component: () => import('@/views/examinations/PrintRCByStudentId.vue'),
    },
    {
      path: 'examinations/reportcard-edit',
      name: 'reporCardEdit',
      component: () => import('@/views/examinations/EditReportCard.vue'),
    },
    {
      path: 'examinations/reportcard-print-by-class',
      name: 'reportCardPrintByClass',
      component: () => import('@/views/examinations/PrintRCByClassId.vue'),
    },
    {
      path: 'examinations/send-activity-marks',
      name: 'SendActivityMarks',
      component: () => import('@/views/examinations/SendActivityMarks.vue'),
    },
    {
      path: 'examinations/automated-report',
      name: 'AutomatedReport',
      component: () => import('@/views/examinations/AutomatedReport.vue'),
    },
    {
      path: 'examinations/range-marks-report',
      name: 'MarksRangeReport',
      component: () => import('@/views/examinations/MarksRangeReport.vue'),
    },
    {
      path: 'examinations/class-average-report',
      name: 'ClassAverageReport',
      component: () => import('@/views/examinations/ClassAverageReport.vue'),
    },
    {
      path: 'examinations/class-register-report',
      name: 'ClassRegisterReport',
      component: () => import('@/views/examinations/ClassRegisterReport.vue'),
    },
    {
      path: 'examinations/session-reportcard-print-by-class',
      name: 'sessionReportCardPrintByClass',
      component: () => import('@/views/examinations/PrintSessionRCByClassId.vue'),
    },
    {
      path: 'examinations/session-reportcard-print-by-student/:studentId',
      name: 'sessionReportCardPrintByStudentId',
      component: () => import('@/views/examinations/PrintSessionRCByStudentId.vue'),
    },
  ],
}
