import { api } from '@/plugins/axios'
import { defineStore } from 'pinia'
import constant from '@/constants/constant'

export const useAuthStore = defineStore('authentication', {
  state: () => ({ user: {} }),
  getters: {
    getUser: state => state.user,
    getUserRole: state => {
      const role = state.user?.roles?.[0]

      return role?.toString() || constant.userRoles.UNAUTHORIZED
    },
  },
  actions: {
    async login(payload) {
      const res = await api.post('/api/auth/signin', payload)
      if (res?.responseObject) {
        const { user, token } = res.responseObject
        this.user = user
        localStorage.setItem('accessToken', token)
        localStorage.setItem('institutionId', user.institutionIds[0])
      }

      return res
    },
    async getUserDetails() {
      if (this.user) return this.user

      const res = await api.get('/api/v1/users')
      if (res?.responseObject) {
        this.user = res.responseObject
        localStorage.setItem('institutionId', this.user.institutionIds[0])
      }

      return res
    },
  },
  persist: true,
})
