<template>
  <div>
    <!-- <notifications position="bottom right" /> -->
    <notifications group="custom" position="top right">
      <template #body="props">
        <!-- <pre>{{ props }}</pre> -->
        <div class="vue-notification-wrapper">
          <div class="vue-notification" :class="props.item.type">
            <v-icon v-if="props.item?.data?.icon">
              {{ props.item.data.icon }}
            </v-icon>
            <div>
              <div class="subtitle-1 text-bold">
                {{ props.item.title }}
              </div>
              <div class="notification-content">
                {{ props.item.text }}
              </div>
            </div>
            <v-icon style="margin-left: auto; height: fit-content" @click="props.close">
              {{ $icons.mdiClose }}
            </v-icon>
          </div>
        </div>
      </template>
    </notifications>
    <!-- <notifications group="custom" position="bottom right">
      <template #body="props">
        <div style="background-color: green">
          <a class="title">
            {{ props.item.title }}
          </a>
          <a class="close" @click="props.close">
            <v-icon>{{ $icons.mdiClose }}</v-icon>
          </a>
          <div v-html="props.item.text"></div>
        </div>
      </template>
    </notifications> -->
  </div>
</template>
<script>
export default {
  props: {
    color: { type: String, default: 'primary' },
    outlined: { type: Boolean },
    depressed: { type: Boolean },
    loading: { type: Boolean },
  },
}
</script>
<style lang="scss">
$primary: #1976d2 !default;
$secondary: #26a69a !default;
$accent: #9c27b0 !default;

// used by dark mode
$dark-page: #121212 !default;
$dark: #1d1d1d !default;

$positive: #21ba45 !default;
$negative: #c10015 !default;
$info: #31ccec !default;
$warning: #f2c037 !default;

.vue-notification {
  padding: 10px;
  margin: 0 5px 5px;
  font-size: 12px;
  color: #ffffff;
  background: #44a4fc;
  border-left: 5px solid $info;
  display: flex !important;
  flex-wrap: nowrap;
  border-radius: 5px;
  gap: 10px;

  &.warn {
    background: $warning;
    border-left-color: #f48a06 !important;
  }

  &.error {
    background: $negative;
    border-left-color: #b82e24 !important;
  }

  &.success {
    background: #21ba45 !important;
    border-left-color: #42a85f !important;
  }
}
</style>
