export default {

  path: '/',
  component: () => import('@/layouts/LayoutContentHorizontalNav.vue'),
  meta: { protected: true },
  children: [
    {
      path: 'accounts/fee/trust',
      name: 'TrustList',
      component: () => import('@/views/accounts/trust/TrustList.vue'),
    },
    {
      path: 'accounts/fee/create',
      name: 'CreateFees',
      component: () => import('@/views/accounts/fees/CreateFees.vue'),
    },
    {
      path: 'accounts/fee/list',
      name: 'ListFees',
      component: () => import('@/views/accounts/fees/ListFees.vue'),
    },
    {
      path: 'accounts/fee/assign-to-class',
      name: 'AssignFeesToClass',
      component: () => import('@/views/accounts/fees/AssignFeesToClass.vue'),
    },
    {
      path: 'accounts/fee/list-for-class',
      name: 'ListFeesForClass',
      component: () => import('@/views/accounts/fees/ListFeesForClass.vue'),
    },
    {
      path: 'accounts/fee/assign-to-student',
      name: 'AssignFeesToStudent',
      component: () => import('@/views/accounts/fees/AssignFeesToStudent.vue'),
    },
    {
      path: 'accounts/fee/list-for-student',
      name: 'ListFeesForStudent',
      component: () => import('@/views/accounts/fees/ListFeesForStudent.vue'),
    },
    {
      path: 'accounts/discount/manage-discount',
      name: 'ManageDiscountGroup',
      component: () => import('@/views/accounts/discount/ManageDiscountGroup.vue'),
    },
    {
      path: 'accounts/assign-fees-to-group',
      name: 'AssignFeesToGroup',
      component: () => import('@/views/accounts/discount/AssignFeesToGroup.vue'),
    },
    {
      path: 'accounts/view-fees-to-group',
      name: 'ViewFeesToGroup',
      component: () => import('@/views/accounts/discount/ViewFeesToGroup.vue'),
    },
    {
      path: 'accounts/view-student-discount',
      name: 'ListOfAssignedDiscounts',
      component: () => import('@/views/accounts/discount/ListOfAssignedDiscounts.vue'),
    },
    {
      path: 'accounts/set-individual-discount',
      name: 'SetIndividualDiscount',
      component: () => import('@/views/accounts/discount/SetIndividualDiscount.vue'),
    },
    {
      path: 'accounts/list-individual-discount',
      name: 'ListIndividualDiscount',
      component: () => import('@/views/accounts/discount/ListIndividualDiscount.vue'),
    },
    {
      path: 'accounts/discount/set-student-discount',
      name: 'SetStudentDiscount',
      component: () => import('@/views/accounts/discount/SetStudentDiscount.vue'),
    },
    {
      path: 'accounts/fee-master/student-fee-record/:studentId',
      name: 'StudentFeeRecord',
      component: () => import('@/views/accounts/fee-master/StudentFeeRecord.vue'),

    },
    {
      path: 'accounts/fee-master/student-payment-record/:studentId',
      name: 'StudentPaymentRecord',
      component: () => import('@/views/accounts/fee-master/StudentPaymentRecord.vue'),
    },
    {
      path: 'accounts/reports/monthwise-dues-report',
      name: 'MonthWiseDuesReport',
      component: () => import('@/views/accounts/reports/MonthWiseDuesReport.vue'),
    },

  ],

}
