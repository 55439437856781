export default {
  path: '/',
  component: () => import('@/layouts/LayoutBlank.vue'),
  children: [
    {
      path: 'login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
    },
    {
      path: 'online/enquiry',
      name: 'OnlineEnquiry',
      component: () => import('@/views/OnlineEnquiry.vue'),
    },
    {
      path: '/ui',
      name: 'uiTesting',
      component: () => import('@/views/UI.vue'),
    },
    {
      path: '/not-found',
      name: 'error-404',
      component: () => import('@/views/Error404.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      component: () => import('@/views/Error404.vue'),
    },
  ],
}
