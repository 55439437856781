import { api, endpoint } from '@/plugins/axios'

const getAllStaff = async () => {
  const res = await api.get(endpoint.STAFF)

  return res
}

const getAllStaffByInstitude = async () => {
  const institutionId = localStorage.getItem('institutionId')
  const res = await api.get(endpoint.STAFF_BY_INSTITUTION, { params: { institutionId } })

  return res
}

const getDeactivatedStaff = async () => {
  const institutionId = localStorage.getItem('institutionId')
  const res = await api.get(endpoint.INACTIVE_STAFF, { params: { institutionId } })

  return res
}

const getStaffAttendanceReport = async payload => {
  const { staffId, ...params } = payload
  const res = await api.get(`/api/v1/staff/${staffId}/attendance`, { params })

  return res
}

/**
 * @param {String} jobFunction - available value TEACHER | DRIVER | GUARD | RECEPTION | ACCOUNTANT
 */
const getStaffByRole = async jobFunction => {
  const institutionId = localStorage.getItem('institutionId')
  const res = await api.get(endpoint.STAFF_ROLE, { params: { institutionId, jobFunction } })

  return res
}

const updateFamilyMembers = async (staffId, payload) => {
  const res = await api.put(endpoint.STAFF_FAMILY_MEMBERS, payload, { params: { staffId } })

  return res
}

const deactivateStaff = async payload => {
  const res = await api.delete(endpoint.STAFF, { data: payload })

  return res
}
const activateStaff = async payload => {
  const res = await api.put(endpoint.ACTIVATE_STAFF, payload)

  return res
}
const getStaff = async params => {
  const res = await api.get(endpoint.GET_STAFF, { params })

  return res
}
const getStaffSchedule = async params => {
  const res = await api.get(endpoint.STAFF_SCHEDULE, { params })

  return res
}

export default {
  getAllStaff,
  getAllStaffByInstitude,
  getDeactivatedStaff,
  getStaffByRole,
  getStaffAttendanceReport,
  updateFamilyMembers,
  deactivateStaff,
  activateStaff,
  getStaff,
  getStaffSchedule,
}
