export default {
  // common used validations
  required: 'Required',
  email: 'Invalid email address',
  number: 'Invalid number',
  mobile: 'Invalid mobile number',
  url: 'Invalid url',
  date: 'Invalid date',
  time: 'Invalid time',
  fulltime: 'Invalid fulltime',
  money: 'Money must be non-negative number Ex.-22.55',
  minLength: length => `min ${length} characters required`,
  maxLength: length => `max ${length} characters allowed`,

  // specific used validations
  adharCard: 'Invalid Adhar number or format',
  panCard: 'Invalid Pan Card number',
  password: 'Field must contain at least one uppercase, lowercase, special character and digit with min 8 chars',
  googleMeetLink: 'Invalid google meet link',
  confirmPassword: 'Password does not match',
}
