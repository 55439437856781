export default {
  path: '/',
  component: () => import('@/layouts/LayoutContentHorizontalNav.vue'),
  meta: { protected: true },
  children: [
    {
      path: '',
      redirect: 'dashboard',
    },
    {
      name: 'dashboard',
      path: 'dashboard',
      component: () => import('@/views/Dashboard.vue'),
    },
    {
      path: 'manage/batch',
      name: 'batch',
      component: () => import('@/views/batch/AddBatch.vue'),
    },
    {
      path: 'manage/class-section',
      name: 'manageclasssection',
      component: () => import('@/views/class-section/Manage.vue'),
    },

    // {
    //   path: 'manage/class-section/:classId',
    //   name: 'manageclass',
    //   component: () => import('@/views/class-section/ManageClass.vue'),
    // },
    {
      path: 'follow-ups',
      name: 'followups',
      component: () => import('@/views/enquiry/FollowUps.vue'),
    },

    {
      path: 'manage/r-p',
      name: 'ManageRNP',
      component: () => import('@/views/roles-permissions/Manage.vue'),
    },
    {
      path: 'manage/r-p/a-p',
      name: 'AddPermissions',
      component: () => import('@/views/roles-permissions/AddPermissions.vue'),
    },
    {
      path: 'manage/r-p/l-p',
      name: 'ListPermissions',
      component: () => import('@/views/roles-permissions/ListPermissions.vue'),
    },
    {
      path: 'manage/manage-staff',
      name: 'ManageStaff',
      component: () => import('@/views/roles-permissions/ManageStaff.vue'),
    },
    {
      path: 'homework/add',
      name: 'AddHomework',
      component: () => import('@/views/homework/AddHomework.vue'),
    },
    {
      path: 'homework/report',
      name: 'HomeworkReport',
      component: () => import('@/views/homework/HomeworkReport.vue'),
    },
    {
      path: 'announcement',
      name: 'Announcement',
      component: () => import('@/views/announcement/Announcement.vue'),
    },
    {
      path: 'student-announcement',
      name: 'StudentAnnouncement',
      component: () => import('@/views/announcement/SendAnnouncementToStudent.vue'),
    },
    {
      path: 'staff-announcement',
      name: 'StaffAnnouncement',
      component: () => import('@/views/announcement/SendAnnouncementToStaff.vue'),
    },
    {
      path: 'online-class',
      name: 'OnlineClass',
      component: () => import('@/views/OnlineClass.vue'),
    },
  ],
}
