import { api, endpoint } from '@/plugins/axios'

// Get Staff Api Function

const getAllSubjects = async () => {
  const institutionId = localStorage.getItem('institutionId')
  const res = await api.get(endpoint.SUBJECT, { params: { institutionId } })

  return res
}

const getSubjectsByClassSection = async filters => {
  const res = await api.get(`${endpoint.SUBJECT}/${filters.classId}/${filters.section}`)

  return res
}

const getSubjectsClassWise = async params => {
  const res = await api.get(endpoint.SUBJECT_LIST, { params })

  return res
}
const getCoScholasticSubjectList = async params => {
  const res = await api.get(endpoint.SUBJECT_CO_SCHOLASTIC_LIST, { params })

  return res
}

const addSubjectToBatch = async payload => {
  const res = await api.put(endpoint.SUBJECT_BATCH, payload)

  return res
}
const addSubjectToClass = async payload => {
  const res = await api.put(endpoint.CLASS_SUBJECT, payload)

  return res
}
const addTeacherToSubject = async payload => {
  const res = await api.put(endpoint.CLASS_SUBJECT_TEACHER, payload)

  return res
}

const createSubject = async payload => {
  const res = await api.post(endpoint.SUBJECT, payload)

  return res
}
const updateSubject = async payload => {
  const res = await api.put(endpoint.SUBJECT, payload)

  return res
}
const updateClassSubject = async (classId, payload) => {
  const res = await api.put(`/api/v1/subject/class}?classId=${classId}`, [payload])

  return res
}

const deleteSubject = async subjectId => {
  const res = await api.delete(`${endpoint.SUBJECT}/${subjectId}`)

  return res
}

export default {
  getSubjectsClassWise,
  getAllSubjects,
  getSubjectsByClassSection,
  addSubjectToBatch,
  addSubjectToClass,
  updateSubject,
  deleteSubject,
  addTeacherToSubject,
  getCoScholasticSubjectList,
  createSubject,
  updateClassSubject,
}
