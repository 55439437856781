import { api } from '@/plugins/axios'

const getClassesByBatch = async params => {
  const res = await api.get(`/api/v1/class/${params.batchId}`)

  return res
}

const getAllBatches = async () => {
  const res = await api.get('/api/v1/batch')

  return res
}
const getAllfee = async institutionId => {
  const res = await api.get(`/api/v1/fs/fees?institutionId=${institutionId}`)

  return res
}
const getReports = async params => {
  const res = await api.get('/api/v1/fees/record/report', { params })

  return res
}
const getStdreports = async params => {
  const res = await api.get('/api/v1/fees/record/student', { params })

  return res
}
const getDuereports = async params => {
  const res = await api.get('/api/v1/fees/record/report/dues', { params })

  return res
}

const getMonthwiseDueReports = async params => {
  const res = await api.get('/api/v1/fees/record/report/month/dues', { params })

  return res
}
export default {
  getAllBatches,
  getClassesByBatch,
  getAllfee,
  getReports,
  getStdreports,
  getDuereports,
  getMonthwiseDueReports,
}
