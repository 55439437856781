<template>
  <div id="noprint" class="flex items-center justify-between px-4 gap-3 h-[152px]">
    <!-- width should be according to instruction but it covers only half page w-[391px] -->
    <div class="flex-grow">
      <h1 class="title !text-red-600">{{ institution.name }}</h1>
      <div>{{ institudeAddress }}</div>
      <div>Contact Numbers: {{ institution?.contacts?.join(', ') || 'NA' }}</div>
      <div>Website: {{ institution.website || 'NA' }}</div>
      <div>Email: {{ institution?.emailIds?.join(', ') || 'NA' }}</div>
    </div>
    <div class="w-fit">
      <img :src="institution.logoUrl" alt="institution logo" />
    </div>
  </div>
</template>

<script>
import { baseApi } from '@/apis'
import { computed, onMounted, ref } from '@/plugins/setup'

export default {
  props: { institution: { type: String } },
  setup(props) {
    const institution = ref({})

    const institudeAddress = computed(() => {
      if (!institution.value.address) return 'NA'
      const { landmark, pinCode, state, country, district } = institution.value.address
      const formatedAddress = `${landmark || ''} ${district || ''} ${state || ''} ${
        country || ''
      } ${pinCode || ''}`

      return formatedAddress.trim() || 'NA'
    })

    const getInstitudeDetails = async () => {
      const res = await baseApi.getInstitution(props.institution)
      if (res) institution.value = res
    }

    onMounted(() => getInstitudeDetails())

    return {
      institution,
      institudeAddress,
    }
  },
}
</script>
