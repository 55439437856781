import { api, endpoint } from '@/plugins/axios'

const getPaymentRecord = async params => {
  const res = await api.get(endpoint.STUDENT_PAYMENT_RECORD, { params })

  return res
}

const getStudentPaymentReceipts = async params => {
  const res = await api.get(endpoint.STUDENT_PAYMENT_RECEIPT, { params })

  return res
}

const findStudentReceiptsById = async receiptId => {
  const res = await api.get(endpoint.FIND_STUDENT_PAYMENT_RECEIPT, { params: { receiptId } })

  return res
}

const collectPayment = async payload => {
  const res = await api.post(endpoint.COLLECT_PAYMENT, payload)

  return res
}
const getStudentPaybles = async studentId => {
  const res = await api.get(endpoint.FEES_COLLECTION_STUDENT, { params: { studentId } })
  if (res.responseObject) {
    const { currentPayables, upcomingPayables, onDemandPayables, ...rest } = res.responseObject
    const data = {
      student: rest,
      currentPayables: currentPayables?.map(x => ({ ...x, payingNow: x.dues })) || [],
      upcomingPayables: upcomingPayables?.map(x => ({ ...x, payingNow: x.dues })) || [],
      onDemandPayables: onDemandPayables?.map(x => ({ ...x, payingNow: x.dues })) || [],
    }

    return data
  }

  return res
}

const getFeesList = async () => {
  const institutionId = localStorage.getItem('institutionId')
  const res = await api.get(endpoint.FEES, { params: { institutionId } })

  return res
}

const createFees = async payload => {
  const res = await api.post(endpoint.FEES, payload)

  return res
}

const getClassFees = async params => {
  const res = await api.get(endpoint.FEES_CLASS, { params })

  return res
}

const createClassFees = async payload => {
  const res = await api.post(endpoint.FEES_CLASS, payload)

  return res
}

const getClassFeesList = async params => {
  const res = await api.get(endpoint.FEES_CLASS_LIST, { params })

  return res
}
const assignFeesToStudent = async payload => {
  const res = await api.post(endpoint.FEES_STUDENT, payload)

  return res
}
const getStudentFees = async params => {
  const res = await api.get(endpoint.FEES_STUDENT, { params })

  return res
}
const getStudentFeesList = async params => {
  const res = await api.get(endpoint.FEES_STUDENT_LIST, { params })

  return res
}
const updateFeesFine = async payload => {
  const res = await api.put(endpoint.FEES_FINE, payload)

  return res
}
const getStudentPayList = async payload => {
  const res = await api.get(endpoint.STUDENT_PAY_LIST, { params: payload })

  return res
}
const updateStudentPayList = async payload => {
  const res = await api.post(endpoint.STUDENT_PAY_LIST, payload)

  return res
}
const getStudentPaybleFee = async payload => {
  const res = await api.get(endpoint.STUDENT_PAID_FEE, { params: payload })

  return res
}
const updateStudentPaybleFee = async payload => {
  const res = await api.post(endpoint.STUDENT_PAID_FEE, payload)

  return res
}

export default {
  getStudentPayList,
  getFeesList,
  getPaymentRecord,
  getStudentPaymentReceipts,
  findStudentReceiptsById,
  collectPayment,
  getStudentPaybles,
  getClassFees,
  createFees,
  createClassFees,
  getClassFeesList,
  assignFeesToStudent,
  getStudentFees,
  getStudentFeesList,
  updateFeesFine,
  getStudentPaybleFee,
  updateStudentPayList,
  updateStudentPaybleFee,
}
