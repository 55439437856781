import { api, endpoint } from '@/plugins/axios'

const getInstitution = async instId => {
  const institutionId = instId || localStorage.getItem('institutionId')
  const institution = localStorage.getItem('institution')

  if (institution) return JSON.parse(institution)

  const res = await api.get(`${endpoint.INSTITUTION}/${institutionId}`)

  if (res?.responseObject) {
    localStorage.setItem('institution', JSON.stringify(res.responseObject))
  }

  return res.responseObject
}

const getAllTrusts = async () => {
  const institutionId = localStorage.getItem('institutionId')
  const res = await api.get(endpoint.TRUST, { params: { institutionId } })

  return res
}
const createTrust = async payload => {
  const res = await api.post(endpoint.TRUST, payload)

  return res
}
const updateTrust = async payload => {
  const res = await api.put(endpoint.TRUST, payload)

  return res
}
const createAnnouncement = async payload => {
  const res = await api.post(endpoint.ANNOUNCEMENT, payload)

  return res
}

const SendAnnouncementToStudent = async payload => {
  const res = await api.post(endpoint.ANNOUNCEMENT_STUDENT, payload)

  return res
}
const SendAnnouncementToStaff = async payload => {
  const res = await api.post(endpoint.ANNOUNCEMENT_STAFF, payload)

  return res
}
const getAdminDashboard = async params => {
  const res = await api.get(endpoint.ADMIN_DASHBOARD, { params })

  return res
}
const createOnlineClass = async payload => {
  const res = await api.post(endpoint.ONLINE_CLASS, payload)

  return res
}
const getOnlineClass = async () => {
  const res = await api.get(endpoint.ONLINE_CLASS)

  return res
}

export default {
  getInstitution,
  getAllTrusts,
  updateTrust,
  createTrust,
  createAnnouncement,
  SendAnnouncementToStudent,
  SendAnnouncementToStaff,
  getAdminDashboard,
  createOnlineClass,
  getOnlineClass,
}
