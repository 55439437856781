<template>
  <div>
    <canvas v-show="false" ref="canvasRef" width="200" height="240" />
    <video ref="cameraRef" :width="320" :height="480" autoplay />
    <v-progress-circular
      v-if="isCameraLoading"
      class="progress-bar"
      :size="30"
      color="primary"
      indeterminate
    />
  </div>
</template>
<script>
import { $notify, onMounted, ref } from '@/plugins/setup'
import { onUnmounted } from 'vue'

export default {
  setup() {
    const cameraRef = ref(null)
    const canvasRef = ref(null)
    const isCameraLoading = ref(false)

    // create camera element
    const createCameraElement = () => {
      isCameraLoading.value = true
      const constraints = {
        audio: false,
        video: true,
      }

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          isCameraLoading.value = false
          cameraRef.value.srcObject = stream
        })
        .catch(error => {
          isCameraLoading.value = false
          $notify({
            type: 'error',
            text: error || "May be the browser didn't support or there is some errors.",
          })
        })
    }

    // remove camera element
    const stopCameraStream = () => {
      // debugger
      const tracks = cameraRef.value.srcObject.getTracks()

      tracks.forEach(track => {
        track.stop()
      })
    }

    const takePhoto = () => {
      stopCameraStream()

      const context = canvasRef.value.getContext('2d')
      context.drawImage(cameraRef.value, 0, 0, 200, 240)
      const canvaImage = document.getElementById('photoTaken').toDataURL('image/jpeg')

      return canvaImage
    }

    onMounted(() => createCameraElement())
    onUnmounted(() => {
      console.log('destroyed')
      stopCameraStream()
    })

    return { cameraRef, canvasRef, isCameraLoading, takePhoto, stopCameraStream }
  },
}
</script>
<style scoped>
video {
  width: 100%;
  height: 100%;
  border: 2px solid black;
  padding: 2px;
  border-radius: 4px;
}

.progress-bar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
