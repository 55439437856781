<template>
  <div>
    <SNotification />
    <transition name="scroll-x-transition" mode="out-in" appear>
      <KeepAlive :max="10">
        <router-view v-if="loaded"></router-view>
      </KeepAlive>
    </transition>
  </div>
</template>

<script>
import { useAuthStore } from '@/stores/authStore'
import { useBatchStore } from '@/stores/batchStore'
import { onBeforeMount, ref } from '@/plugins/setup'

export default {
  setup() {
    const authStore = useAuthStore()
    const batchStore = useBatchStore()
    const loaded = ref(false)

    const getUserDetails = async () => {
      const institutionId = localStorage.getItem('institutionId')
      if (!institutionId) {
        loaded.value = true

        return
      }

      const res = await authStore.getUserDetails()
      if (res) {
        await batchStore.getBatches()
        loaded.value = true
      }
    }

    onBeforeMount(() => getUserDetails())

    return { loaded }
  },
}
</script>
