import '@/controls/controls'
import '@/styles/app.css'
import '@/styles/styles.scss'
import '@/utils/dayjs'
import icons from '@/utils/icons'
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
import Print from 'vue-print-nb'
import './notify'
import './validation'

Vue.use(Print)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.prototype.$icons = icons
