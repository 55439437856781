import { api, endpoint } from '@/plugins/axios'

const getStudentAttendance = async payload => {
  const { classId, section, batchId } = payload
  const res = await api.get(`api/v1/attendance/class/${classId}/section/${section}/students`, { params: { batchId } })

  return res
}

const getClassAttendanceByDate = async payload => {
  const { classId, section, date } = payload
  const res = await api.get(`api/v1/attendance/class/${classId}/section/${section}`, { params: { date } })

  return res
}

const postAttendance = async payload => {
  const res = await api.post(endpoint.ATTENDANCE, payload)

  return res
}

const updateAttendance = async payload => {
  const res = await api.put(endpoint.ATTENDANCE, payload)

  return res
}

const getAttendanceReport = async (classId, section, params) => {
  const res = await api.get(`${endpoint.GET_ATTENDANCE_REPORT}/${classId}/section/${section}/report`, { params })

  return res
}

const getAbsentStudents = async payload => {
  const { classId, section, date } = payload
  const res = await api.get(`${endpoint.GET_ATTENDANCE_REPORT}/${classId}/section/${section}/absent`, { params: { date } })

  return res
}

const sendAbsentReportToParents = async payload => {
  const { classId, section, date, templateId } = payload
  const res = await api.post(`${endpoint.GET_ATTENDANCE_REPORT}/${classId}/section/${section}/students/report`, null, { params: { date, templateId } })

  return res
}

const getAttendance = async params => {
  const res = await api.get(`${endpoint.GET_ATTENDANCE}`, { params })

  return res
}

const getMessageTemplates = async params => {
  const res = await api.get(`${endpoint.TEMPLATE_MESSAGE}`, { params })

  return res
}

// const createMessageTemplates = async (payload) => {
//   const res = await api.post(`${endpoint.TEMPLATE_MESSAGE}`,payload)
//   return res
// }

export default {
  getAttendanceReport,
  getAbsentStudents,
  getAttendance,
  getMessageTemplates,
  getStudentAttendance,
  getClassAttendanceByDate,
  postAttendance,
  updateAttendance,
  sendAbsentReportToParents,
}
