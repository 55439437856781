import Vue from 'vue'
import patterns from './patterns'

// eslint-disable-next-line consistent-return
const funcValidation = fnstring => {
  const [fn, length] = fnstring.split(':')
  if (patterns[fn]) {
    return patterns[fn](length)
  }
  console.warn('[validator]: validation rule not found for:', fn)
}

const validate = rules => {
  const rulesRes = []
  if (rules) {
    rules.split('|').forEach(r => {
      if (patterns[r]) {
        rulesRes.push(patterns[r])
      } else if (funcValidation(r)) {
        rulesRes.push(funcValidation(r))
      }
    })
  }

  return rulesRes
}

Vue.prototype.$validate = validate

export default validate
