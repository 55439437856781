<template>
  <SDialog
    :value="value"
    :title="title"
    :btn-label="{ save: btnLabel }"
    @input="$emit('input', $event)"
    @save="$emit('ok', $event)"
  >
    <slot>
      <p class="text-xl">
        {{ description }}
      </p>
    </slot>
  </SDialog>
</template>
<script>
export default {
  props: {
    value: { type: Boolean },
    title: { type: String },
    description: { type: String },
    btnLabel: { type: String, default: 'Ok' },
  },
}
</script>
