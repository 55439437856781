import { api, endpoint } from '@/plugins/axios'

const getBatches = async () => {
  const institutionId = localStorage.getItem('institutionId')
  const res = await api.get(`${endpoint.BATCH}/${institutionId}/institution`)

  return res
}

const createBatch = async payload => {
  const res = await api.post(endpoint.BATCH, payload)

  return res
}
const updateBatch = async payload => {
  const res = await api.put(endpoint.BATCH, payload)

  return res
}
const deleteBatch = async batchId => {
  const res = await api.put(`${endpoint.BATCH}/${batchId}`)

  return res
}

// class api's
const getClassesByBatch = async params => {
  const res = await api.get(`${endpoint.CLASS}/${params.batchId}`)

  return res
}

const createClass = async payload => {
  const res = await api.post('/api/v1/class', payload)

  return res
}

const createSection = async payload => {
  const res = await api.post('/api/v1/section', payload)

  return res
}

const deleteSubjectTeacher = async payload => {
  const res = await api.delete('/api/v1/class/subject/teacher', { data: payload })

  return res
}

export default {
  getBatches,
  createBatch,
  updateBatch,
  deleteBatch,
  getClassesByBatch,
  createClass,
  createSection,
  deleteSubjectTeacher,
}
