import { api, endpoint } from '@/plugins/axios'

// exam coscholastic crud api
const getAllCoScholasticGrades = async () => {
  const institutionId = localStorage.getItem('institutionId')
  const res = await api.get(endpoint.EXAM_COSCHOLASTIC_GRADE_ALL, { params: { institutionId } })

  return res
}

const createCoScholasticGrade = async payload => {
  const res = await api.post(endpoint.EXAM_COSCHOLASTIC_GRADE, payload)

  return res
}

const updateCoScholasticGrade = async payload => {
  const res = await api.put(endpoint.EXAM_COSCHOLASTIC_GRADE, payload)

  return res
}

const deleteCoScholasticGrade = async id => {
  const res = await api.delete(`${endpoint.EXAM_COSCHOLASTIC_GRADE}/${id}`)

  return res
}

// exam coscholastic crud api
const getAllScholasticGrades = async () => {
  const institutionId = localStorage.getItem('institutionId')
  const res = await api.get(endpoint.EXAM_SCHOLASTIC_GRADE_ALL, { params: { institutionId } })

  return res
}

const createScholasticGrade = async payload => {
  const res = await api.post(endpoint.EXAM_SCHOLASTIC_GRADE, payload)

  return res
}

const updateScholasticGrade = async payload => {
  const res = await api.put(endpoint.EXAM_SCHOLASTIC_GRADE, payload)

  return res
}

const deleteScholasticGrade = async id => {
  const res = await api.delete(`${endpoint.EXAM_SCHOLASTIC_GRADE}/${id}`)

  return res
}

// exam coscholastic  marks crud api
const getAllCoScholasticMarks = async () => {
  const institutionId = localStorage.getItem('institutionId')
  const res = await api.get(endpoint.EXAM_COSCHOLASTIC_MARKS_ALL, { params: { institutionId } })

  return res
}

const createCoScholasticMarks = async payload => {
  const res = await api.post(endpoint.EXAM_COSCHOLASTIC_MARKS, payload)

  return res
}

const updateCoScholasticMarks = async payload => {
  const res = await api.put(endpoint.EXAM_COSCHOLASTIC_MARKS, payload)

  return res
}

const deleteCoScholasticMarks = async id => {
  const res = await api.delete(`${endpoint.EXAM_COSCHOLASTIC_MARKS}/${id}`)

  return res
}

export default {
  // coscholastic api's
  getAllCoScholasticGrades,
  createCoScholasticGrade,
  updateCoScholasticGrade,
  deleteCoScholasticGrade,

  // sholastic api's
  getAllScholasticGrades,
  createScholasticGrade,
  updateScholasticGrade,
  deleteScholasticGrade,

  // co-scholastic marks api's
  getAllCoScholasticMarks,
  createCoScholasticMarks,
  updateCoScholasticMarks,
  deleteCoScholasticMarks,
}
