<template>
  <div class="my-3">
    <div
      v-bind="getRootProps()"
      class="rounded-md cursor-pointer bg-blue-50 text-center"
      :class="{ 'border-dashed border-2 border-primary': true }"
    >
      <slot>
        <div class="p-4 flex items-center flex-nowrap">
          <!-- <q-icon class="text-blue-400 mr-3" :name="iconName" size="xl" /> -->
          <label for="contacts-import" class="cursor-pointer rounded-md ellipsis select-none">
            <div class="font-semibold text-gray-900 ellipsis text-start">
              {{ title }}
            </div>
            <div v-if="!value" class="text-sm text-gray-500 text-start ellipsis">
              File Format: {{ accept }}
            </div>
            <div v-else class="text-sm text-gray-500 text-start ellipsis">
              File : {{ value || 'Not Uploaded' }}
            </div>
            <!-- <span v-else class="text-base text-gray-600"> {{ title }} </span> -->
          </label>
        </div>
      </slot>
      <input v-bind="getInputProps()" />
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDropzone } from 'vue3-dropzone'
import { documentApi } from '@/apis'
import { ref, $notify } from '@/plugins/setup'

export default {
  props: {
    label: { type: String, default: '' },
    title: { type: String, default: '' },
    accept: { type: String, default: '' },
    icon: { type: String, default: 'fa-regular fa-images' },
    value: { type: [String, Array], required: true },
    multiple: { type: Boolean },
  },
  emits: ['update:model-value', 'success'],
  setup(props, { emit }) {
    const files = ref([])

    const onDrop = (acceptFiles /* rejectReasons */) => {
      files.value = acceptFiles
      uploadFile()

      // emit("update:model-value", acceptFiles);
    }
    const { getRootProps, getInputProps /* ...rest */ } = useDropzone({
      multiple: props.multiple,
      accept: props.accept,
      onDrop,
    })

    const uploadFile = async () => {
      // if files not selected don't execute upload api
      if (files.value.length < 1) return

      const formData = new FormData()

      files.value.forEach(file => formData.append('file', file))

      // upload files
      const res = await documentApi.uploadFile(formData)
      if (res) {
        $notify({ type: 'Success', text: 'File uploaded successfully' })
        emit('update:model-value', res)
        emit('success', res)
      }
    }

    return {
      files,
      getRootProps,
      getInputProps,
    }
  },
}
</script>
