import Vue from 'vue'
import Notifications from 'vue-notification'
import { icons } from './setup'

Vue.use(Notifications)

const defaultSettings = { type: 'success', group: 'custom', data: { icon: icons.mdiCheckCircle } }

const notifyTypes = {
  success: { title: 'Success', data: { icon: icons.mdiCheckCircle } },
  error: { title: 'Error', data: { icon: icons.mdiAlert } },
  warn: { title: 'Info', data: { icon: icons.mdiAlert } },
}

const notify = config => {
  // resolve custom config
  const resolvedConfig = typeof config === 'string' ? { title: config } : config

  // resolve default type config
  const typeDefaults = resolvedConfig?.type ? notifyTypes[resolvedConfig?.type] : {}

  // trigger notify
  Vue.notify({ ...defaultSettings, ...typeDefaults, ...resolvedConfig })
}

export default notify
