<template>
  <v-textarea
    v-if="type === 'textarea'"
    :value="value"
    :label="label"
    :type="type"
    :rules="rules"
    :disabled="disabled"
    :autofocus="autofocus"
    outlined
    dense
    :hide-details="hideDetails"
    :placeholder="placeholder"
    :hint="hint"
    validate-on-blur
    @input="onInput"
    @blur="$emit('blur', $event)"
  />

  <v-text-field
    v-else
    :value="value"
    :label="label"
    :type="getType"
    :rules="rules"
    :disabled="disabled"
    :autofocus="autofocus"
    outlined
    dense
    :hide-details="hideDetails"
    :placeholder="placeholder"
    :hint="hint"
    validate-on-blur
    :append-icon="appendIcon"
    @input="onInput"
    @click:append="showPassword = !showPassword"
  />
</template>
<script>
import { debounce, ref, computed, icons } from '@/plugins/setup'

export default {
  props: {
    label: { type: String, default: '' },
    type: { type: String, default: 'text' },
    value: { type: [String, Number, Array] },
    rules: { type: Array, default: undefined },
    disabled: { type: Boolean },
    hideDetails: { type: Boolean },
    autofocus: { type: Boolean },
    placeholder: { type: String, default: '' },
    hint: { type: String, default: '' },
    debounce: { type: [String, Number], default: 0 },
  },
  setup(props, { emit }) {
    const showPassword = ref(false)

    const onInput = debounce(event => {
      emit('input', event)
    }, props.debounce)

    const getType = computed(() => {
      if (props.type === 'password' && showPassword.value) return 'text'

      return props.type
    })

    const appendIcon = computed(() => {
      if (props.type !== 'password') return undefined

      return showPassword.value ? icons.mdiEyeOffOutline : icons.mdiEyeOutline
    })

    return { onInput, showPassword, getType, appendIcon }
  },
}
</script>
