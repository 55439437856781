<template>
  <v-data-table
    :value="value"
    :headers="headers"
    :items="items"
    :items-per-page="itemsPerPage"
    :show-select="showSelect"
    :item-key="itemKey"
    :hide-default-footer="hideFooter"
    @input="$emit('input', $event)"
  >
  </v-data-table>
</template>
<script>
export default {
  props: {
    label: { type: String, default: '' },
    value: { type: Array },
    items: { type: Array, default: () => [] },
    itemsPerPage: { type: Number, default: 10 },
    itemKey: { type: String },
    showSelect: { type: Boolean },
    hideFooter: { type: Boolean },
  },
}
</script>
