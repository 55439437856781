import constants from '@/constants/constant'
import router from '@/router'
import day, { date } from '@/utils/dayjs'
import { exportToCsv, exportToPdf } from '@/utils/exports'
import helper, { range } from '@/utils/helper'
import icons from '@/utils/icons'
import {
  computed,
  getCurrentInstance,
  inject,
  onBeforeMount,
  onMounted,
  provide,
  ref,
  watch,
  watchEffect,
  nextTick,
  toRefs,
  reactive,
} from 'vue'
import { useBatchStore } from '@/stores/batchStore'
import { api, axios, endpoint } from './axios'
import { debounce } from './debouncer'
import $notify from './notify'
import validate from './validation'

const getVuetify = () => {
  const ins = getCurrentInstance()?.proxy

  return ins?.$vuetify ? ins.$vuetify : null
}

const useRouter = () => {
  const vm = getCurrentInstance().proxy

  const state = reactive({ route: vm.$route })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

export {
  ref,
  computed,
  onMounted,
  onBeforeMount,
  getCurrentInstance,
  watchEffect,
  provide,
  inject,
  watch,
  nextTick,
  toRefs,
  reactive,
  debounce,
  validate,
  icons,
  api,
  endpoint,
  axios,
  date,
  constants,
  router,
  exportToCsv,
  exportToPdf,
  $notify,
  range,
  useRouter,
  helper,
  day,
  getVuetify,
  useBatchStore,
}
