import { api, endpoint } from '@/plugins/axios'

const addContent = async payload => {
  const res = await api.post(endpoint.ADD_CONTENT, payload)

  return res
}
const updateContent = async payload => {
  const res = await api.put(endpoint.ADD_CONTENT, payload)

  return res
}
const getContent = async params => {
  const res = await api.get(`${endpoint.ADD_CONTENT}/all`, { params })

  return res
}

export default { addContent, getContent, updateContent }
