<template>
  <v-menu offset-y left :min-width="minWidth">
    <template #activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot name="default">
          <v-icon>{{ icon || $icons.mdiDotsVertical }}</v-icon>
          <!-- <v-btn icon> </v-btn> -->
        </slot>
      </span>
    </template>

    <v-list>
      <slot name="menu:prepend" />
      <template v-for="m in menu">
        <v-list-item v-if="!hide[m.name]" :key="m.label" link>
          <v-list-item-title :class="m.css" @click="onItemClick(m)">
            <v-icon size="20" class="me-2" :class="m.css">
              {{ m.icon }}
            </v-icon>
            <span>{{ m.label }}</span>
          </v-list-item-title>
          <!-- <v-list-item-action v-if="m.badge">
            <v-badge inline color="error" content="2">{{ badge }}</v-badge>
          </v-list-item-action> -->
        </v-list-item>
        <v-divider v-if="m.divider" :key="`divider-${m.label}`" />
      </template>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    menu: { type: Array, default: () => [] },
    icon: { type: String, default: '' },
    minWidth: { type: String, default: '' },
    nudgeBottom: { type: Number, default: 14 },
    hide: { type: Object, default: () => ({}) },
  },
  data() {
    return {}
  },
  methods: {
    onItemClick(item) {
      const eventName = item.name.toLowerCase().replaceAll(/\s+/g, '-')
      this.$emit(eventName, item)
      this.$emit('item-click', item.name)
    },
  },
}
</script>
