import { api, endpoint } from '@/plugins/axios'

const getHomeworkReport = async params => {
  const res = await api.get(endpoint.HOMEWORK, { params })

  return res
}
const getHomeworkByDate = async params => {
  const res = await api.get(endpoint.HOMEWORK_BY_DATE, { params })

  return res
}

const createHomework = async payload => {
  const res = await api.post(endpoint.HOMEWORK, payload)

  return res
}

const publishHomework = async homeworkId => {
  const res = await api.get(endpoint.HOMEWORK_PUBLISH, { params: { homeworkId } })

  return res
}

export default {
  getHomeworkReport,
  getHomeworkByDate,
  createHomework,
  publishHomework,
}
