<template>
  <div>
    <div id="student-photo-container">
      <div id="stud-pic">
        <v-progress-circular
          v-if="loading"
          class="progress-bar"
          :size="30"
          color="primary"
          indeterminate
        />
        <!-- image -->
        <img v-if="imageUrl" :src="imageUrl" />
        <div v-else>
          <b>200 x 240<br />Photo here</b>
        </div>
      </div>
      <canvas v-show="false" id="photoTaken" ref="canvasRef" width="200" height="240" />
      <!-- action buttons -->
      <div class="action-btns">
        <v-btn color="primary" @click="$refs.fileRef.click()">
          <v-icon height="40" width="40">
            {{ $icons.mdiFileUpload }}
          </v-icon>
          Upload
        </v-btn>
        <input ref="fileRef" type="file" hidden @change="updatePhoto($event)" />
        <v-btn class="m-t-8" color="primary" @click="toggleCamera">
          <v-icon height="40" width="40" class="me-2" alt="upload-from-camera">
            {{ $icons.mdiCamera }}
          </v-icon>
          Take Photo
        </v-btn>
      </div>
    </div>
    <!-- camera dialog -->
    <SDialog
      v-model="isCameraOpen"
      title="Take Photo"
      :btn-label="{ save: 'Capture' }"
      @save="takePhoto"
      @input="stopCameraStream()"
    >
      <!-- <SCamera ref="cameraRef" /> -->
      <video ref="cameraRef" :width="320" :height="480" autoplay />
      <v-progress-circular
        v-if="isCameraLoading"
        class="progress-bar"
        :size="30"
        color="primary"
        indeterminate
      />
    </SDialog>
  </div>
</template>
<script>
import { documentApi } from '@/apis'
import { $notify, onMounted, ref, watch } from '@/plugins/setup'
import { dataURItoBlob } from '@/utils/helper'

export default {
  props: { src: { type: String, default: '' } },
  setup(props, { emit }) {
    const imageUrl = ref('')

    const isCameraOpen = ref(false)
    const isShotPhoto = ref(false)
    const isCameraLoading = ref(false)
    const loading = ref(false)
    const cameraRef = ref(null)
    const canvasRef = ref(null)
    const imageFile = ref([])

    const openCameraModal = () => {
      isCameraOpen.value = true
    }
    const createCameraElement = () => {
      isCameraLoading.value = true
      const constraints = {
        audio: false,
        video: true,
      }

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          isCameraLoading.value = false
          cameraRef.value.srcObject = stream
        })
        .catch(error => {
          isCameraLoading.value = false
          $notify({
            type: 'error',
            text: error || "May be the browser didn't support or there is some errors.",
          })
        })
    }

    const stopCameraStream = () => {
      const tracks = cameraRef.value.srcObject.getTracks()

      tracks.forEach(track => {
        track.stop()
      })
    }

    const uploadPhoto = async isCaptured => {
      let canva = ''
      let blobFile = ''
      if (isCaptured === 'true') {
        canva = document.getElementById('photoTaken').toDataURL('image/jpeg')
        blobFile = dataURItoBlob(canva, 'userProfile')
        imageUrl.value = document.getElementById('photoTaken').toDataURL('image/jpeg')
      } else {
        blobFile = isCaptured
        imageUrl.value = isCaptured
      }
      imageFile.value = []
      const imageFromBlob = new File([blobFile], new Date().getUTCMilliseconds(), {
        type: 'name',
        lastModified: new Date().getTime(),
      })
      imageFile.value.push(imageFromBlob)

      // upload photo
      const file = new FormData()
      file.append('file', imageFile.value[0])
      loading.value = true
      const res = await documentApi.uploadFile(file)
      loading.value = false
      if (res) {
        imageUrl.value = res.message
        emit('upload-success', res.message)
      }
    }

    const toggleCamera = () => {
      isCameraOpen.value = true
      createCameraElement()
    }
    const updatePhoto = event => {
      imageUrl.value = ''
      const photo = event.target.files[0]
      uploadPhoto(photo)
    }

    const takePhoto = () => {
      isShotPhoto.value = true
      const FLASH_TIMEOUT = 50
      setTimeout(() => {
        isShotPhoto.value = false
      }, FLASH_TIMEOUT)

      stopCameraStream()

      const context = canvasRef.value.getContext('2d')
      context.drawImage(cameraRef.value, 0, 0, 200, 240)

      uploadPhoto('true')
      setTimeout(() => {
        isCameraOpen.value = false
      }, 1000)
    }

    watch(
      () => props.src,
      () => (imageUrl.value = props.src),
    )

    onMounted(() => (imageUrl.value = props.src))

    // expose properties and functions
    return {
      loading,
      canvasRef,
      isCameraOpen,
      isCameraLoading,
      cameraRef,
      imageUrl,
      openCameraModal,
      updatePhoto,
      toggleCamera,
      takePhoto,
      uploadPhoto,
      stopCameraStream,
    }
  },
}
</script>
<style lang="scss" scoped>
video {
  width: 100%;
  height: 100%;
  border: 2px solid black;
  padding: 2px;
  border-radius: 4px;
}

// progress bar
.progress-bar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#student-photo-container {
  display: flex;
  // margin-left: auto;
  // width: 100%;
  justify-content: flex-end;

  .action-btns {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    justify-content: flex-end;
    .m-t-8 {
      margin-top: 8px;
    }
  }
  .v-btn {
    width: 100%;
  }

  #stud-pic {
    border: 2px solid black;
    padding: 2px;
    width: 200px;
    height: 240px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    position: relative;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
  }
}

@media only screen and (max-width: 484px) {
  #student-photo-container {
    flex-direction: column;
    align-items: center;
    .action-btns {
      margin-top: 8px;
      margin-left: 0px;
    }
  }
}
</style>
