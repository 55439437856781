import { api, endpoint } from '@/plugins/axios'

const createRegistration = async payload => {
  const res = await api.post(endpoint.CREATE_REGISTRATION, payload)

  return res
}

// const updateContent = async payload => {
//   const res = await api.put(endpoint.ADD_CONTENT, payload)

//   return res
// }
const getRegistration = async params => {
  const res = await api.get(`${endpoint.CREATE_REGISTRATION}`, { params })

  return res
}

export default { createRegistration, getRegistration }
