/* eslint no-useless-escape: "off" */
import messages from './messages'

// patterns
const url = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
const mobile = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
const money = /^\d+(?:\.\d{2})$/
const email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
const adharCard = /^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/
const panCard = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/
const password = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
const googleMeetLink = new RegExp('^https?://meet.google.com/[a-z0-9_-]{10,}$')

const isEmpty = val => val === '' || val === undefined || val === null

// export const isEmpty = value => {
//   if (value === null || value === undefined || value === '') {
//     return true
//   }

//   if (Array.isArray(value) && value.length === 0) {
//     return true
//   }

//   return false
// }
export default {
  required: v => !isEmpty(v) || messages.required,
  url: v => !v || url.test(v) || messages.url,
  email: v => !v || email.test(v) || messages.email,

  // date: (v, rules) => rules.date(v) || messages.date,
  // time: (v, rules) => rules.time(v) || messages.time,
  // fulltime: (v, rules) => rules.fulltime(v) || messages.fulltime,
  number: v => !v || !Number.isNaN(v) || messages.number,
  mobile: v => !v || mobile.test(v) || messages.mobile,
  money: v => money.test(v) || messages.money,
  adharCard: v => adharCard.test(v) || messages.adharCard,
  panCard: v => panCard.test(v) || messages.panCard,
  password: v => password.test(v) || messages.password,
  minLength(length) {
    return v => (!!v && v.length >= length) || messages.minLength(length)
  },
  maxLength(length) {
    return v => (!!v && v.length <= length) || messages.maxLength(length)
  },
  googleMeetLink: v => googleMeetLink.test(v) || messages.googleMeetLink,
  matchPassword(pass) {
    const rule = v => v === pass || messages.confirmPassword

    return rule
  },

}
