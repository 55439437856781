<template>
  <div class="flex relative items-center gap-3 mx-16 px-2 py-2 border border-yellow-500 rounded">
    <!-- <div> -->
    <div class="w-fit px-4 mb-1 absolute">
      <img :src="institution.logoUrl" alt="institution logo" width="100" />
    </div>
    <div class="mx-auto">
      <h1 class="text-slate-600 text-4xl font-semibold">
        {{ institution.name }}
      </h1>
      <div class="text-lg">{{ institudeAddress }}</div>
      <div class="text-md">Contact Us: {{ institution?.contacts?.join(', ') || 'NA' }}</div>
      <!-- <div>Website: {{ institution.website || 'NA' }}</div> -->
      <div>Email: {{ institution?.emailIds?.join(', ') || 'NA' }}</div>
      <h1 class="text-slate-600 text-xl font-semibold text-center">ADMIT CARD</h1>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { baseApi } from '@/apis'
import { computed, onMounted, ref } from '@/plugins/setup'

export default {
  props: { institution: { type: String } },
  setup(props) {
    const institution = ref({})

    const institudeAddress = computed(() => {
      if (!institution.value.address) return 'NA'
      const { landmark, pinCode, state, country, district } = institution.value.address
      const formatedAddress = `${landmark || ''} ${district || ''} ${state || ''} ${
        country || ''
      } ${pinCode || ''}`

      return formatedAddress.trim() || 'NA'
    })

    const getInstitudeDetails = async () => {
      const res = await baseApi.getInstitution(props.institution)
      if (res) institution.value = res
    }

    onMounted(() => getInstitudeDetails())

    return {
      institution,
      institudeAddress,
    }
  },
}
</script>
