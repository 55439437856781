import { api, endpoint, multipartHeader } from '@/plugins/axios'

const uploadFile = async payload => {
  const res = await api.post(endpoint.IMAGE, payload, { headers: multipartHeader })

  return res
}
const getDocuments = async ownerId => {
  const res = await api.get(`${endpoint.DOCUMENTS}/${ownerId}`)

  return res
}

const addDocument = async payload => {
  // upload file
  const formData = new FormData()
  formData.append('file', payload.documentUrl)
  const response = await uploadFile(formData)

  // create document
  const res = await api.post(endpoint.DOCUMENTS, { ...payload, documentUrl: response.message })

  return res
}

export default {
  addDocument,
  getDocuments,
  uploadFile,
}
