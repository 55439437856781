import { api, endpoint } from '@/plugins/axios'

const getEnquiries = async params => {
  const res = await api.get(endpoint.ENQUIRY_SEARCH, { params })

  return res
}

const getEnquiryById = async enquiryId => {
  const res = await api.get(`${endpoint.ENQUIRY}/${enquiryId}`)

  return res
}

const postEnquiry = async payload => {
  const res = await api.post(endpoint.ENQUIRY, payload)

  return res
}

const updateEnquiryStatus = async payload => {
  const res = await api.put(endpoint.ENQUIRY, payload)

  return res
}

const deleteEnquiry = async enquiryId => {
  const res = await api.delete(endpoint.ENQUIRY_DELETE, { params: { enquiryId } })

  return res
}

const getFollowUpsByEnquiryId = async enquiryId => {
  const res = await api.get(`${endpoint.ENQUIRY}/${enquiryId}/followups`)

  return res
}

const addFollowUp = async payload => {
  const res = await api.post(endpoint.ENQUIRY_FOLLOWUP, payload)

  return res
}

// online school enquiry
const createSchoolEnquiry = async payload => {
  const res = await api.post(endpoint.SCHOOL_ENQUIRY, payload)

  return res
}

export default {
  getEnquiries,
  getEnquiryById,
  postEnquiry,
  updateEnquiryStatus,
  deleteEnquiry,
  getFollowUpsByEnquiryId,
  addFollowUp,
  createSchoolEnquiry,
}
