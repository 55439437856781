export default {
  path: '/',
  component: () => import('@/layouts/LayoutContentHorizontalNav.vue'),
  meta: { protected: true },
  children: [
    {
      path: '/manage/students',
      name: 'ManageStudents',
      component: () => import('@/views/students/ManageStudents.vue'),
    },
    {
      path: '/manage/studentProfile',
      name: 'StudentProfile',
      component: () => import('@/views/students/StudentsProfile.vue'),
    },
    {
      path: '/manage/hostelTransportStudent',
      name: 'HostelTransportStudents',
      component: () => import('@/views/students/HostelAndTransport.vue'),
    },
    {
      path: '/manage/deletedStudents',
      name: 'DeactivatedStudents',
      component: () => import('@/views/students/DeactivatedStudents.vue'),
    },
    {
      path: '/manage/studentOrder',
      name: 'DisplaySequence',
      component: () => import('@/views/students/DisplaySequence.vue'),
    },
    {
      path: '/manage/arrangeRollNo',
      name: 'ArrangeRollNo',
      component: () => import('@/views/students/ArrangeRollNo.vue'),
    },
    {
      path: '/manage/printloginId',
      name: 'PrintLoginId',
      component: () => import('@/views/students/PrintLoginId.vue'),
    },
    {
      path: '/manage/classcount',
      name: 'Classcount',
      component: () => import('@/views/students/ClassCount.vue'),
    },

    // {
    //   path: '/manage/ManageBatch',
    //   name: 'Managebatch',
    //   component: () => import('@/views/students/ManageBatch.vue'),
    // },
    {
      path: '/manage/StudentCallRecord',
      name: 'CallRecord',
      component: () => import('@/views/students/StudentCallRecord.vue'),
    },
    {
      path: '/manage/ViewPreviousBatchStudent',
      name: 'previousbatchstudent',
      component: () => import('@/views/students/PreviousBatchStudent.vue'),
    },
    {
      path: '/manage/students/:studentId/update',
      name: 'EditStudent',
      component: () => import('@/views/students/AddStudent.vue'),
    },
    {
      path: '/manage/students/add',
      name: 'AddNewStudent',
      component: () => import('@/views/students/AddStudent.vue'),
      beforeEnter(to, from, next) {
      // Clear the form data before entering the AddStudent.vue component
        next(vm => {
          vm.clearFormData()
        })
      },
    },
    {
      path: '/manage/searchStudents',
      name: 'SearchStudent',
      component: () => import('@/views/students/SearchStudent.vue'),
    },
    {
      path: '/manage/admitCard',
      name: 'AdmitCard',
      component: () => import('@/views/students/AdmitCard.vue'),
    },
  ],
}
