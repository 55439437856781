<template>
  <v-dialog :value="value" :max-width="maxWidth" scrollable @input="updateModel">
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text class="h-1/2 pt-2">
        <SForm ref="formRef">
          <slot />
        </SForm>
      </v-card-text>
      <v-card-actions :class="btnPosition">
        <v-spacer></v-spacer>
        <SBtn
          outlined
          type="reset"
          :label="buttonLabels.cancel"
          :color="color"
          @click="$emit('cancel', $event), updateModel(false)"
        />
        <SBtn :label="buttonLabels.save" :color="saveColor" :loading="loading" @click="onSave" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed, ref } from 'vue'

export default {
  props: {
    value: { type: Boolean },
    title: { type: String },
    color: { type: String },
    saveColor: { type: String },
    btnPosition: { type: String },
    loading: { type: Boolean },
    noReset: { type: Boolean },
    maxWidth: { type: String, default: '500px' },
    btnLabel: {
      type: Object,
      default() {
        return { save: 'Save', cancel: 'Cancel' }
      },
    },
  },
  setup(props, { emit }) {
    const formRef = ref(null)
    const buttonLabels = computed(() => ({ save: 'Save', cancel: 'Cancel', ...props.btnLabel }))

    const updateModel = ev => {
      // if (!props.noReset) formRef.value.reset()
      // else
      formRef.value.resetValidation()
      emit('input', ev)
    }

    const onSave = async () => {
      const isValid = await formRef.value.validate()
      if (isValid) emit('save')
    }

    return { formRef, updateModel, onSave, buttonLabels }
  },
}
</script>
