// @ts-check
export const isObject = obj => typeof obj === 'object' && obj !== null

export const dataURItoBlob = (dataURI, fileName = 'thumbnail') => {
  // convert base64/URLEncoded data component to raw binary data held in a string

  let byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    //  byteString = Buffer.from(dataURI.split(',')[1], 'base64').toString('base64')
    byteString = atob(dataURI.split(',')[1])
  } else {
    byteString = unescape(dataURI.split(',')[1])
  }

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(arrayBuffer)
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i)
  }
  const dataView = new DataView(arrayBuffer)
  const fileBlob = new Blob([dataView], { type: mimeString })

  return new File([fileBlob], fileName, { type: mimeString })
}

export const createDate = date => {
  const newDate = date.toString().split(' ')

  return `${newDate[2]} ${newDate[1]} ${newDate[3]}`
}

// generate array of given numbers range
export const range = (start = 0, stop = 10, step = 1) => {
  const length = (stop - start) / step + 1

  return Array.from({ length }, (_, i) => start + i * step)
}

const addNumbers = (a, b) => {
  return parseFloat(a || 0) + parseFloat(b || 0)
}

export const getSum = (arr, field = 'payingNow', initialValue = 0) => {
  // reduce arr
  return arr.reduce((a, b) => addNumbers(a, b[field]), initialValue)
}

export const extractKeys = (obj, keys = [], includes = false) => {
  const newObj = {}
  if (!obj) {
    console.warn('Given value is not a valid object')

    return null
  }
  Object.keys(obj).forEach(key => {
    if (keys.includes(key) === includes) {
      newObj[key] = obj[key]
    }
  })

  return newObj
}

export const removeEmptyKeys = obj => {
  const newObj = { ...obj }

  Object.keys(newObj).forEach(key => {
    if (!newObj[key]) delete newObj[key]
  })

  return newObj
}

export const avatarText = (value, length = 2) => {
  if (!value) return ''
  const nameArray = value.split(' ')
  const words = nameArray.map(word => word.charAt(0).toUpperCase()).join('')

  return words.substring(0, length)
}

/* eslint no-await-in-loop: "off" */
export const validateForm = async array => {
  // find undefined arr value
  console.log('validate form')
  for (let index = 0; index < array.length; index += 1) {
    const element = array[index]
    if (element.value?.validate) {
      const isValid = await element.value.validate()
      if (!isValid) return { tab: index }
    }
  }

  return true
}

/**
 * This func access nested value of an obj from string  path or from a fun
 * @param {object} obj object we have to access value from
 * @param {function|string} path formating function or string
 * @returns nested value or formated value
 */
export const getObjValue = (obj, path) => {
  try {
    if (path === undefined) return ''
    if (typeof path === 'function') return path(obj)

    const keys = path.split('.')
    let value = { ...obj }

    keys.forEach(key => (value = value?.[key]))

    return value
  } catch (error) {
    console.error('[Error]: ', error)

    return ''
  }
}

/* eslint-disable no-param-reassign */
export const convertNumberToWords = num => {
  const wordsArr = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen']
  const tensArr = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
  const crores = Math.floor(num / 10000000)
  num %= 10000000
  const lakhs = Math.floor(num / 100000)
  num %= 100000
  const thousands = Math.floor(num / 1000)
  num %= 1000
  const hundreds = Math.floor(num / 100)
  num %= 100
  const tens = Math.floor(num / 10)
  const ones = Math.floor(num % 10)
  let words = ''
  if (crores > 0) {
    words += `${convertNumberToWords(crores)} Crore `
  }
  if (lakhs > 0) {
    words += `${convertNumberToWords(lakhs)} Lakh `
  }
  if (thousands > 0) {
    words += `${convertNumberToWords(thousands)} Thousand `
  }
  if (hundreds > 0) {
    words += `${convertNumberToWords(hundreds)} Hundred `
  }
  if (tens > 0 || ones > 0) {
    if (tens < 2) {
      words += `${wordsArr[tens * 10 + ones]} `
    } else {
      words += `${tensArr[tens]} ${wordsArr[ones]} `
    }
  }

  return words.trim()
}

/**
 *
 * @param {Array} arr
 * @param {String|number} value
 * @param {string} field
 * @returns
 */
export const findBy = (arr, value, field = 'id') => {
  try {
    const filteredValue = arr.find(x => x?.[field] === value)

    return filteredValue
  } catch (error) {
    console.error('[Error]: ', error)

    return ''
  }
}

/**
 * this function add index no in the array of object
 * @param {Array} arr array we have to add index
 * @param {String} indexName key name for index
 * @returns indexed array
 */
export const addIndex = (arr, indexName = 'SerialNo') => {
  try {
    const indexedArray = arr.map((d, index) => ({ ...d, [indexName]: index + 1 }))

    return indexedArray
  } catch (error) {
    console.error('[Error]: ', error)

    return arr
  }
}

/**
 * this function change's `camelCase` to `sentance case`
 * @param {string} textString string we have to change case
 * @returns
 */
export const toSentanceCase = textString => {
  const upperCaseRegex = /[A-Z]/g

  return textString.replace(upperCaseRegex, letter => ` ${letter}`)
}

// default export all func
export default {
  isObject,
  range,
  getSum,
  createDate,
  dataURItoBlob,
  extractKeys,
  avatarText,
  removeEmptyKeys,
  validateForm,
  getObjValue,
  convertNumberToWords,
  findBy,
  addIndex,
  toSentanceCase,
}
