import Vue from 'vue'
import { createPinia, PiniaVuePlugin } from 'pinia'
import piniaPluginPersistedstate, { createPersistedState } from 'pinia-plugin-persistedstate'
import SecureLS from 'secure-ls'

const ls = new SecureLS({ isCompression: false })

Vue.use(PiniaVuePlugin)

const pinia = createPinia()

pinia.use(piniaPluginPersistedstate)

// pinia.use(createPersistedState({
//   // storage: {
//   //   getItem: key => ls.get(key),
//   //   setItem: (key, value) => ls.set(key, value),
//   //   removeItem: key => ls.remove(key),
//   // },
// }))

export default pinia
