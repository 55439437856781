<template>
  <v-btn
    :type="type"
    :outlined="outlined"
    :depressed="depressed"
    :color="color"
    :loading="loading"
    :ripple="false"
    :small="small"
    :disabled="disabled"
    @click="$emit('click', $event)"
  >
    <slot>
      <v-icon v-if="icon" :class="{ 'mr-2': label }">
        {{ icon }}
      </v-icon>
      <span v-if="label">{{ label }}</span>
    </slot>
  </v-btn>
</template>
<script>
export default {
  props: {
    label: { type: String },
    type: { type: String },
    color: { type: String, default: 'primary' },
    outlined: { type: Boolean },
    depressed: { type: Boolean },
    loading: { type: Boolean },
    disabled: { type: Boolean },
    small: { type: Boolean },
    icon: { type: String },
  },
}
</script>
