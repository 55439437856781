export default {
  path: '/',
  component: () => import('@/layouts/LayoutContentHorizontalNav.vue'),
  meta: { protected: true },
  children: [
    {
      path: 'accounts/collect-fee',
      name: 'CollectFee',
      component: () => import('@/views/accounts/collect-fee/CollectFee.vue'),
    },
    {
      path: 'accounts/pay-fee/:studentId',
      name: 'PayFee',
      component: () => import('@/views/accounts/collect-fee/PayFee.vue'),
    },

    {
      path: 'accounts/get-receipt',
      name: 'GetReceipt',
      component: () => import('@/views/accounts/collect-fee/GetReceipt.vue'),
    },
    {
      path: 'accounts/student-payment-records/:studentId',
      name: 'StudentPaymentRecords',
      component: () => import('@/views/accounts/collect-fee/StudentPaymentRecords.vue'),
    },
    {
      path: 'accounts/student-passbook/:studentId',
      name: 'StudentPassbook',
      component: () => import('@/views/accounts/collect-fee/StudentPassbook.vue'),
    },
    {
      path: 'accounts/fees-certificate/:studentId',
      name: 'FeesCertificate',
      component: () => import('@/views/accounts/collect-fee/FeesCertificate.vue'),
    },
    {
      path: 'accounts/search-receipt',
      name: 'SearchReceipt',
      component: () => import('@/views/accounts/collect-fee/SearchReceipt.vue'),
    },
    {
      path: 'accounts/student-wallet',
      name: 'StudentWallet',
      component: () => import('@/views/accounts/collect-fee/StudentWallet.vue'),
    },
    {
      path: 'accounts/print-receipt/:receiptId',
      name: 'PrintReceipt',
      component: () => import('@/views/accounts/collect-fee/PrintReceipt.vue'),
    },

  ],
}
