<template>
  <v-select
    :value="value"
    :type="type"
    :rules="rules"
    :items="items"
    :label="label"
    :item-text="itemText"
    :item-value="itemValue"
    :disabled="disabled"
    :hide-details="hideDetails"
    :placeholder="placeholder"
    :multiple="multiple"
    clearable
    outlined
    dense
    validate-on-blur
    @input="$emit('input', $event)"
  ></v-select>
</template>
<script>
export default {
  props: {
    label: { type: String, default: '' },
    type: { type: String, default: '' },
    items: {
      type: Array,
      default() {
        return []
      },
    },
    itemText: { type: [String, Function], default: undefined },
    itemValue: { type: [String, Function], default: undefined },
    value: { type: [String, Number, Object, Array] },
    rules: { type: Array },
    disabled: { type: Boolean },
    hideDetails: { type: Boolean },
    multiple: { type: Boolean },
    placeholder: { type: String, default: '' },
  },
}
</script>
