<template>
  <SDialog
    no-reset
    :value="value"
    :title="title"
    @input="$emit('input', $event), (form = {})"
    @save="addDocument"
  >
    <SSelect
      v-model="form.documentType"
      label="Document Type"
      :items="constants.documentTypes"
      :rules="$validate('required')"
    />
    <STextField
      v-model="form.name"
      label="Document Name"
      :rules="$validate('required|minLength:5')"
      class="my-1"
    />
    <SFileUploader v-model="form.documentUrl" label="File Upload" :rules="$validate('required')" />
    <v-data-table :headers="headers" :items="documents">
      <template #item.preview="{ item }">
        <SImg width="32px" height="32px" :src="item.documentUrl" />
      </template>
      <template #item.actions="{ item }">
        <a :href="item.documentUrl" target="_blank">
          <v-icon>{{ $icons.mdiEyeOutline }}</v-icon>
        </a>
      </template>
    </v-data-table>
  </SDialog>
</template>
<script>
import { documentApi } from '@/apis'
import { $notify, constants, ref } from '@/plugins/setup'

export default {
  props: {
    title: { type: String, default: '' },
    value: { type: Boolean },
    ownerId: { type: [String, Number], default: '' },
  },
  setup(props) {
    const form = ref({})
    const documents = ref([])

    const getDocuments = async () => {
      const res = await documentApi.getDocuments(props.ownerId)
      if (res) {
        console.log(res)
        documents.value = res.responseObject
      }
    }

    const addDocument = async () => {
      const res = await documentApi.addDocument({ ...form.value, ownerId: props.ownerId })
      if (res) {
        $notify({ type: 'success', text: 'Document uploaded successfully' })
        form.value = {}
        getDocuments()
      }
    }

    return {
      constants,
      addDocument,
      form,
      getDocuments,
      documents,
      headers: [
        { text: '', value: 'preview' },
        { text: 'Name', value: 'name' },
        { text: 'Document Type', value: 'documentType' },
        { text: 'Actions', value: 'actions' },
      ],
    }
  },
}
</script>
