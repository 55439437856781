<template>
  <SDialog
    :value="value"
    btn-position="flex  justify-center float-center mr-36 "
    :btn-label="{ save: 'Change' }"
    @input="$emit('input', $event)"
  >
    <div class="text-center">
      <img src="http://localhost:8080/img/logo.aa90e8db.jpg" width="150" class="mx-auto" />
      <h2 class="font-semibold text-xl text-primary">DDYPPPIS</h2>

      <div class="mt-5 space-y-2">
        <h3 class="text-gray-800 text-2xl font-bold sm:text-3xl">Update your password</h3>
      </div>
    </div>
    <form class="mt-8 space-y-5">
      <STextField
        v-model="form.Password"
        label="Current Password"
        :rules="$validate('required')"
        type="password"
      />
      <STextField
        v-model="form.newPassword"
        label="New Password"
        :rules="$validate('required|password')"
        type="password"
      />
      <STextField
        v-model="form.confirmPassword"
        label="Confirm Password"
        :rules="$validate(`required|matchPassword:${form.newPassword}`)"
        type="password"
      />
    </form>
  </SDialog>
</template>
<script>
import { ref } from 'vue'

// import { ref } from '@/plugins/setup'

// import { transportApi, documentApi } from '@/apis'

// const instId = localStorage.getItem('institutionId')
export default {
  props: { value: { type: Boolean } },

  setup(props) {
    const form = ref({})

    return { form }
  },
}
</script>
