export default {
  path: '/',
  component: () => import('@/layouts/LayoutContentHorizontalNav.vue'),
  meta: { protected: true },
  children: [
    {
      path: 'attendance/add-edit',
      name: 'AddAttendance',
      component: () => import('@/views/attendance/AddAttendance.vue'),
    },
    {
      path: 'attendance/view',
      name: 'ViewAttendance',
      component: () => import('@/views/attendance/ViewAttendance.vue'),
    },
    {
      path: 'attendance/report-parents',
      name: 'ReportParents',
      component: () => import('@/views/attendance/ReportParents.vue'),
    },
    {
      path: 'attendance/reports',
      name: 'Reports',
      component: () => import('@/views/attendance/Reports.vue'),
    },
    {
      path: 'attendance/attendance-register',
      name: 'AttendanceRegister',
      component: () => import('@/views/attendance/AttendanceRegister.vue'),
    },
    {
      path: 'attendance/get-attendance',
      name: 'attendanceTable',
      component: () => import('@/views/attendance/AttendanceRegisterTable.vue'),
    },
  ],
}
