export default {
  path: '/',
  component: () => import('@/layouts/LayoutContentHorizontalNav.vue'),
  meta: { protected: true },
  children: [
    {
      path: 'enquiry',
      name: 'enquiry',
      component: () => import('@/views/enquiry/Enquiry.vue'),
    },
    {
      path: 'enquiry/add',
      name: 'addenquiry',
      component: () => import('@/views/enquiry/AddEnquiry.vue'),
    },
    {
      path: 'enquiry/view',
      name: 'viewenquiry',
      component: () => import('@/views/enquiry/ViewEnquiry.vue'),
    },
    {
      path: 'onlineEnquiry',
      name: 'onlineEnquiry',
      component: () => import('@/views/enquiry/OnlineEnquiry.vue'),
    },
    {
      path: 'onlineEnquiry/view',
      name: 'viewOnlineEnquiry',
      component: () => import('@/views/enquiry/ViewOnlineEnq.vue'),
    },
    {
      path: 'onlineEnquiry/add',
      name: 'addOnlineEnquiry',
      component: () => import('@/views/enquiry/AddOnlineEnq.vue'),
    },
  ],
}
