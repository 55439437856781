<template>
  <img
    v-if="src && isValid"
    :width="width"
    :height="height"
    style="object-fit: cover"
    :src="src"
    @error="isValid = false"
  />
  <v-icon v-else>
    {{ errorIcon || $icons.mdiFileDocumentOutline }}
  </v-icon>
</template>
<script>
import { ref } from '@/plugins/setup'

export default {
  props: {
    width: { type: String, default: '32px' },
    height: { type: String, default: '32px' },
    src: { type: String, default: '' },
    errorIcon: { type: String, default: '' },
  },
  setup() {
    const isValid = ref(true)

    return { isValid }
  },
}
</script>
