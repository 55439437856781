import { api, endpoint } from '@/plugins/axios'

const getAllModules = async () => {
  const institutionId = localStorage.getItem('institutionId')
  const res = await api.get(endpoint.MODULES, { params: { institutionId } })

  return res
}
const getAllPermissions = async () => {
  const institutionId = localStorage.getItem('institutionId')
  const res = await api.get(endpoint.PERMISSIONS, { params: { institutionId } })

  return res
}
const updatePermission = async payload => {
  const res = await api.put(endpoint.PERMISSIONS, payload)

  return res
}

const getRoles = async () => {
  const institutionId = localStorage.getItem('institutionId')
  const res = await api.get(endpoint.ROLES, { params: { institutionId } })

  return res
}

const createRole = async payload => {
  const res = await api.post(endpoint.ROLES, payload)

  return res
}

const updateRole = async payload => {
  const res = await api.put(endpoint.ROLES, payload)

  return res
}

export default {
  getAllModules,
  getAllPermissions,
  updatePermission,
  getRoles,
  createRole,
  updateRole,
}
