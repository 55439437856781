<template>
  <v-avatar
    :color="src ? '' : 'primary'"
    :class="src ? '' : 'v-avatar-light-bg primary--text'"
    size="32"
    @click="$emit('click', $event)"
  >
    <v-img v-if="src" :src="src"></v-img>
    <span v-else-if="label" class="font-weight-medium">
      {{ avatarText(label) }}
    </span>
    <v-icon v-else small>
      {{ $icons.mdiImageOutline }}
    </v-icon>
  </v-avatar>
</template>
<script>
import { avatarText } from '@/utils/helper'

export default {
  props: {
    label: { type: String },
    src: { type: String },
    color: { type: String, default: 'primary' },
  },
  setup() {
    return { avatarText }
  },
}
</script>
