export default {
  path: '/',
  component: () => import('@/layouts/LayoutContentHorizontalNav.vue'),
  meta: { protected: true },
  children: [
    {
      path: '/econtent/add-content',
      name: 'addContent',
      component: () => import('@/views/econtent/AddContent.vue'),
    },
  ],
}
