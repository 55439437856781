export default {
  path: '/',
  component: () => import('@/layouts/LayoutContentHorizontalNav.vue'),
  meta: { protected: true },
  children: [
    {
      path: 'manage/staffs/add',
      name: 'AddNewStaff',
      component: () => import('@/views/staffs/AddStaff.vue'),
    },
    {
      path: 'manage/staffs',
      name: 'ManageStaffs',
      component: () => import('@/views/staffs/ManageStaffs.vue'),
    },
    {
      path: 'manage/staffattendence',
      name: 'StaffAttendence',
      component: () => import('@/views/staffs/StaffAttendence.vue'),
    },
    {
      path: 'manage/mannualattendence',
      name: 'MannualStaffAttendence',
      component: () => import('@/views/staffs/MannualStaffAttendence.vue'),
    },
    {
      path: 'manage/individuaiAttendence',
      name: 'individualAttendence',
      component: () => import('@/views/staffs/IndividualAttendence.vue'),
    },
    {
      path: 'manage/CustomStaffAttendence',
      name: 'CustomStaffAttendence',
      component: () => import('@/views/staffs/CustomStaffAttendence.vue'),
    },
    {
      path: 'manage/StaffAttendenceRegister',
      name: 'StaffAttendenceRegister',
      component: () => import('@/views/staffs/StaffAttendenceRegister.vue'),
    },
    {
      path: 'manage/MentorDiaryReport',
      name: 'MentorDiaryReport',
      component: () => import('@/views/staffs/MentorDiaryReport.vue'),
    },
    {
      path: 'manage/StaffOrder',
      name: 'StaffOrder',
      component: () => import('@/views/staffs/StaffOrder.vue'),
    },
    {
      path: 'manage/staffs/:staffId/update',
      name: 'EditStaff',
      component: () => import('@/views/staffs/AddStaff.vue'),
    },
    {
      path: 'manage/staffs/inactive',
      name: 'DeactivatedStaff',
      component: () => import('@/views/staffs/DeactivatedStaff.vue'),
    },
    {
      path: 'manage/staff-schedule',
      name: 'StaffSchedule',
      component: () => import('@/views/staffs/StaffSchedule.vue'),
    },
  ],
}
