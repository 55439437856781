<template>
  <v-textarea
    :value="value"
    :label="label"
    :type="type"
    :rules="rules"
    :disabled="disabled"
    outlined
    dense
    :hide-details="hideDetails"
    :placeholder="placeholder"
    @input="$emit('input', $event)"
  ></v-textarea>
</template>
<script>
export default {
  props: {
    label: { type: String },
    type: { type: String },
    value: { type: [String, Number] },
    rules: { type: Array },
    disabled: { type: Boolean },
    hideDetails: { type: Boolean },
    placeholder: { type: String },
  },
}
</script>
