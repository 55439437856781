<template>
  <div>
    <v-file-input
      :value="value"
      :rules="rules"
      :label="label"
      :disabled="disabled"
      :hide-details="hideDetails"
      :placeholder="placeholder"
      clearable
      outlined
      :prepend-icon="prependIcon || $icons.mdiFileDocumentOutline"
      dense
      :multiple="multiple"
      validate-on-blur
      @change="$emit('input', $event)"
    >
    </v-file-input>
  </div>
</template>
<script>
export default {
  props: {
    label: { type: String, default: '' },
    value: { type: [File, String, Object, Array] },
    rules: { type: Array },
    disabled: { type: Boolean },
    multiple: { type: Boolean },
    hideDetails: { type: Boolean },
    placeholder: { type: String },
    prependIcon: { type: String },
  },
}
</script>
