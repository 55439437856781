<template>
  <v-form ref="formRef" class="py-3" @submit.prevent="onSubmit">
    <slot />
  </v-form>
</template>
<script>
export default {
  props: {},
  methods: {
    async validate() {
      const res = await this.$refs.formRef.validate()

      return res
    },
    resetValidation() {
      this.$refs.formRef.resetValidation()
    },
    reset() {
      this.$refs.formRef.reset()
    },
    async onSubmit() {
      const isValid = await this.validate()
      if (isValid) this.$emit('submit')
    },
  },
}
</script>
