import { api, endpoint } from '@/plugins/axios'

/*  {
   studentId: '644045d98555ed33960b7d71',
   termId: '64400d708555ed33960b7d64',
   classId: '6440453b8555ed33960b7d6f',
   examId: '64400d8b8555ed33960b7d65'
     } */
const getStudentReportCard = async params => {
  const res = await api.get(endpoint.STUDENT_REPORT_CARD, { params })

  return res
}

/*  {
   termId: '64400d708555ed33960b7d64',
   classId: '6440453b8555ed33960b7d6f',
   examId: '64400d8b8555ed33960b7d65'
     } */
const getStudentsReportCardByClass = async params => {
  const res = await api.get(endpoint.STUDENTS_REPORT_CARDS, { params })

  return res
}

const getReportCardForEdit = async params => {
  const res = await api.get(endpoint.STUDENT_REPORT_CARD_MARKS, { params })

  return res
}

const updateReportCardSuject = async payload => {
  const res = await api.put(endpoint.STUDENT_REPORT_CARD_MARKS, payload)

  return res
}
const deleteReportCardSubject = async payload => {
  const res = await api.delete(endpoint.STUDENT_REPORT_CARD_MARKS, { data: { ids: payload } })

  return res
}

const getStudentsReportCardByClassAfterFreeze = async params => {
  const res = await api.get(endpoint.STUDENTS_REPORT_CARDS_AFTER_FREEZE, { params })

  return res
}
const getStudentReportCardSession = async params => {
  const res = await api.get(endpoint.STUDENT_REPORT_CARD_SESSION, { params })

  return res
}
const getStudentsReportCardByClassAfterFreezeSession = async params => {
  const res = await api.get(endpoint.STUDENTS_REPORT_CARDS_AFTER_FREEZE_SESSION, { params })

  return res
}

export default {
  getStudentReportCard,
  getStudentsReportCardByClass,

  getReportCardForEdit,
  updateReportCardSuject,
  deleteReportCardSubject,
  getStudentsReportCardByClassAfterFreeze,
  getStudentReportCardSession,
  getStudentsReportCardByClassAfterFreezeSession,
}
