<template>
  <div>
    <v-col cols="12" md="12" sm="12">
      {{ title }}
    </v-col>
    <v-divider class="mb-4"></v-divider>
    <v-row>
      <v-col cols="12" md="3" sm="6">
        <STextField
          v-model="form.addLineOne"
          :disabled="disabled"
          label="Address Line 1"
          @input="onUpdate"
        />
      </v-col>
      <v-col cols="12" md="3" sm="6">
        <STextField
          v-model="form.addLineTwo"
          :disabled="disabled"
          label="Address Line 2"
          @input="onUpdate"
        />
      </v-col>
      <v-col cols="12" md="3" sm="6">
        <STextField
          v-model="form.landmark"
          :disabled="disabled"
          label="Landmark"
          @input="onUpdate"
        />
      </v-col>
      <v-col cols="12" md="3" sm="6">
        <STextField v-model="form.country" :disabled="disabled" label="Country" @input="onUpdate" />
      </v-col>
      <v-col cols="12" md="3" sm="6">
        <SSelect
          v-model="form.state"
          :disabled="disabled"
          :items="stateList"
          label="State"
          @input="onUpdate"
        />
      </v-col>
      <v-col cols="12" md="3" sm="6">
        <SSelect
          v-model="form.district"
          :disabled="disabled"
          :items="getDistrict()"
          label="District"
          @input="onUpdate"
        />
      </v-col>
      <v-col cols="12" md="3" sm="6">
        <STextField
          v-model="form.pinCode"
          :disabled="disabled"
          type="text"
          label="Pincode"
          @input="onUpdate"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { onMounted, ref, watch } from 'vue'
import { stateOptions } from '@/utils/states'

export default {
  props: {
    title: { type: String, default: 'Address' },
    disabled: { type: Boolean },
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  setup(props, { emit }) {
    const stateList = stateOptions.map(({ state }) => state)

    const form = ref({
      addLineOne: '',
      addLineTwo: '',
      city: '',
      country: '',
      landmark: '',
      pinCode: '',
      state: '',
    })

    watch(
      () => props.value,
      () => (form.value = { ...form.value, ...props.value }),
    )

    const onUpdate = () => {
      emit('input', { ...form.value })
    }

    const getDistrict = () => {
      if (form.value.state) {
        const selectedState = stateOptions.find(item => item.state === form.value.state)

        return selectedState?.districts || []
      }

      return []
    }

    onMounted(() => (form.value = { ...form.value, ...props.value }))

    return {
      stateList,
      getDistrict,
      form,
      onUpdate,
    }
  },
}
</script>
