import endpoint from '@/constants/endpoint'
import axios from 'axios'
import Vue from 'vue'
import { $notify } from './setup'

const multipartHeader = { 'Content-Type': 'multipart/form-data' }

axios.interceptors.response.use(
  response => response,
  error => {
    console.log('error')
    if (error.response.status === 401) {
      localStorage.clear()
      window.location.href = '/login'
    } else {
      $notify({ title: 'Error', text: 'Something went wrong!', type: 'error' })

      return Promise.reject(error)
    }

    return null
  },
)

// axios custom instance
const api = axios.create({ baseURL: process.env.VUE_APP_API_BASE })

api.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

api.interceptors.response.use(
  res => {
    if (res?.data && !res.data.error) {
      return res.data
    }
    const errorMessage = res?.data?.errorMessage
    $notify({ text: errorMessage || 'Something went wrong!', type: 'error' })

    return null
  },
  error => {
    // bad request
    const errorMessage = error?.response?.data?.errorMessage
    console.log('error in api call', error)

    $notify({ text: errorMessage || 'Something went wrong!', type: 'error' })

    return null
  },
)
Vue.prototype.$api = api

export default api

export { api, endpoint, axios, multipartHeader }
