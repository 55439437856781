import { getObjValue } from '../helper'

const wrapCsvValue = (val, formatFn, row) => {
  let formatted = formatFn !== undefined ? formatFn(val, row) : val

  formatted = formatted === undefined || formatted === null ? '' : String(formatted)

  formatted = formatted.split('"').join('""')

  return `"${formatted}"`
}

const downloadFile = (csvFile, filename = 'table') => {
  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement('a')
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

// table exporter

const exportToCsv = (columns, rows, fileName) => {
  // naive encoding to csv format
  const content = [columns.map(col => wrapCsvValue(col.text))]
    .concat(
      rows.map(row => columns.map(col => wrapCsvValue(getObjValue(row, col.value), col.format, row)).join(',')),
    )
    .join('\r\n')

  // download table
  downloadFile(content, fileName)
}

export default exportToCsv
