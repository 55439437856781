export default {
  path: '/',
  component: () => import('@/layouts/LayoutContentHorizontalNav.vue'),
  meta: { protected: true },
  children: [
    {
      path: 'accounts/reports/fee-wise-reports',
      name: 'FeesWiseReport',
      component: () => import('@/views/accounts/reports/FeeWiseReport.vue'),
    },
    {
      path: 'accounts/reports/registration-charges',
      name: 'RegistrationCharges',
      component: () => import('@/views/accounts/reports/RegistrationCharges.vue'),
    },
    {
      path: 'accounts/reports/student-wise-reports',
      name: 'StudentWiseReports',
      component: () => import('@/views/accounts/reports/StudentWiseReports.vue'),
    },
    {
      path: 'accounts/reports/paymode-reports',
      name: 'PayModeReport',
      component: () => import('@/views/accounts/reports/PayModeReport.vue'),
    },
    {
      path: 'accounts/reports/consolidated-report',
      name: 'ConsolidatedReport',
      component: () => import('@/views/accounts/reports/ConsolidatedReport.vue'),
    },
    {
      path: 'accounts/reports/online-payment',
      name: 'OnlinePayment',
      component: () => import('@/views/accounts/reports/OnlinePayment.vue'),
    },
    {
      path: 'accounts/reports/assigned-fee',
      name: 'ViewAssignedFees',
      component: () => import('@/views/accounts/reports/ViewAssignedFees.vue'),
    },
    {
      path: 'accounts/reports/current-dues',
      name: 'CurrentDues',
      component: () => import('@/views/accounts/reports/CurrentDues.vue'),
    },
    {
      path: 'accounts/reports/blockby-dues',
      name: 'BlockbyDues',
      component: () => import('@/views/accounts/reports/BlockbyDues.vue'),
    },
    {
      path: 'accounts/reports/demand-bill',
      name: 'DemandBill',
      component: () => import('@/views/accounts/reports/DemandBill.vue'),
    },
    {
      path: 'accounts/reports/daily-collection-report',
      name: 'DailyCollectionReport',
      component: () => import('@/views/accounts/reports/DailyCollectionReport.vue'),
    },
    {
      path: 'accounts/reports/user-wise-collection',
      name: 'UserwiseCollection',
      component: () => import('@/views/accounts/reports/UserwiseCollection.vue'),
    },
    {
      path: 'accounts/reports/bus-wise-collection',
      name: 'BuswiseCollection',
      component: () => import('@/views/accounts/reports/BuswiseCollection.vue'),
    },
    {
      path: 'accounts/reports/view-cancelled-report',
      name: 'ViewCancelledReports',
      component: () => import('@/views/accounts/reports/ViewCancelledReports.vue'),
    },
    {
      path: 'accounts/reports/wallet-report',
      name: 'WalletReports',
      component: () => import('@/views/accounts/reports/WalletReports.vue'),
    },
    {
      path: 'accounts/reports/month-wisepaid-report',
      name: 'MonthWisePaidReports',
      component: () => import('@/views/accounts/reports/MonthWisePaidReports.vue'),
    },
    {
      path: 'accounts/reports/fee-analysis-report',
      name: 'FeeAnalysisReport',
      component: () => import('@/views/accounts/reports/FeeAnalysisReport.vue'),
    },
  ],
}
