<template>
  <router-link to="/" class="d-flex align-center text-decoration-none">
    <v-img :src="themeConfig.app.logo" height="45px" width="45px" alt="logo" contain class="me-3" />
    <h2 class="font-semibold text-xl text-primary">
      {{ themeConfig.app.name }}
    </h2>
  </router-link>
</template>
<script>
import themeConfig from '@themeConfig'

export default {
  props: {},
  setup() {
    return { themeConfig }
  },
}
</script>
