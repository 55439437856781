<template>
  <!-- testing for now it will be global component for all delete popups -->
  <SDialog
    :value="value"
    :title="`Delete ${title}`"
    :btn-label="{ save: 'Delete' }"
    @input="$emit('input', $event)"
    @save="deleteEntity"
  >
    <v-card>
      <v-card-title> Are you sure you want to delete this {{ title }}? </v-card-title>
    </v-card>
  </SDialog>
</template>
<script>
export default {
  props: {
    value: { type: Boolean },
    title: { type: String, default: '' },
  },
  setup() {
    const deleteEntity = () => {
      // debugger
      // TODO: call delete api
    }

    return { deleteEntity }
  },
}
</script>
