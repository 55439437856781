export default {
  path: '/',
  component: () => import('@/layouts/LayoutContentHorizontalNav.vue'),
  meta: { protected: true },
  children: [
    {
      path: '/registration/add-registration',
      name: 'addRegistration',
      component: () => import('@/views/registration/AddRegistration.vue'),
    },
    {
      path: '/registration/get-registration',
      name: 'getRegistration',
      component: () => import('@/views/registration/RegistrationTable.vue'),
    },
  ],
}
