import { api, endpoint } from '@/plugins/axios'

const getAllSkills = async () => {
  const institutionId = localStorage.getItem('institutionId')
  const res = await api.get(endpoint.EXAM_SKILLS_ALL, { params: { institutionId } })

  return res
}

const getAllSubSkills = async () => {
  const institutionId = localStorage.getItem('institutionId')
  const res = await api.get(endpoint.EXAM_SUB_SKILLS_ALL, { params: { institutionId } })

  return res
}
const getSubSkillsByParentId = async skillId => {
  const res = await api.get(endpoint.EXAM_SUB_SKILLS_BY_PARENT, { params: { skillId } })

  return res
}

const createSkill = async payload => {
  const institutionId = localStorage.getItem('institutionId')
  const res = await api.post(endpoint.EXAM_SKILLS, { ...payload, institutionId })

  return res
}
const createChildSkill = async payload => {
  const institutionId = localStorage.getItem('institutionId')
  const res = await api.post(endpoint.EXAM_SUB_SKILLS, { ...payload, institutionId })

  return res
}

const updateSkill = async payload => {
  const res = await api.put(endpoint.EXAM_SKILLS, payload)

  return res
}
const updateChildSkill = async payload => {
  const res = await api.put(endpoint.EXAM_SUB_SKILLS, payload)

  return res
}

const deleteSkillById = async skillId => {
  const res = await api.delete(`${endpoint.EXAM_SKILLS}/${skillId}`)

  return res
}
const deleteChildSkillById = async skillId => {
  const res = await api.delete(`${endpoint.EXAM_SUB_SKILLS}/${skillId}`)

  return res
}

export default {
  getAllSkills,
  getAllSubSkills,
  getSubSkillsByParentId,
  createSkill,
  createChildSkill,
  updateSkill,
  updateChildSkill,
  deleteSkillById,
  deleteChildSkillById,
}
