export default {
  // user roles
  userRoles: {
    ADMIN: 'ADMIN',
    TEACHER: 'TEACHER',
    RECEPTIONIST: 'RECEPTIONIST',
    TRANSPORT: 'TRANSPORT',
    ACCOUNTANT: 'ACCOUNTANT',
    UNAUTHORIZED: 'UNAUTHORIZED',
    EXAM: 'EXAM',
    COORDINATOR: 'COORDINATOR',
    ACADEMIC_COORDINATOR: 'ACADEMIC_COORDINATOR',
    ENQUIRY: 'ENQUIRY',
    PRO: 'PRO', // enquiry
    ACCOUNTS: 'ACCOUNTS',
  },

  // student module
  maritalStatusOptions: ['MARRIED', 'UNMARRIED', 'WIDOWED', 'SINGLE'],
  bloodGroupOptions: [
    { label: 'O-', value: 'O_NEG' },
    { label: 'A-', value: 'A_NEG' },
    { label: 'B-', value: 'B_NEG' },
    { label: 'O+', value: 'O_POS' },
    { label: 'A+', value: 'A_POS' },
    { label: 'B+', value: 'B_POS' },
  ],
  jobTypeOptions: ['PERMANENT', 'TEMPORARY'],
  genderOptions: ['MALE', 'FEMALE', 'OTHERS'],
  facilities: ['HOSTEL', 'TRANSPORT', 'NONE'],
  studentDeactivationReasons: [
    'TC',
    'RESTRICTED',
    'FEE DEFAULTER',
    'DROPOUT',
    'DUPLICATE ENTRY',
    'CONTINUOUS ABSENT',
  ],
  studentCommentType: ['POSITIVE', 'NEGATIVE'],
  documentTypes: [
    'CHARACTER_CERTIFICATE',
    'MARKSHEET',
    'AADHAR',
    'BIRTH_CERTIFICATE',
    'DL',
    'PAN',
    'CASTE_CERTIFICATE',
    'TC',
    'PASSPORT',
    'OTHERS',
  ],
  searchFieldOptions: [
    {
      label: 'Student Name',
      value: 'NAME',
    },
    {
      label: "Father's Name",
      value: 'FATHER_NAME',
    },
    {
      label: "Mother's Name",
      value: 'MOTHER_NAME',
    },
    {
      label: 'Mobile Number',
      value: 'MOBILE',
    },
    {
      label: 'Admission Number',
      value: 'ADMISSION_NO',
    },
    {
      label: 'Roll Number',
      value: 'ROLL_NO',
    },
  ],

  degreeOptions: ['BACHELORS', 'MASTERS', 'DIPLOMA'],
  relations: ['Father', 'Mother', 'Spouse', 'Son', 'Daughter'],
  categoryOptions: ['GENERAL', 'OBC', 'EBC', 'SC', 'ST'],
  religionOptions: ['HINDU', 'MUSLIM', 'SIKH', 'CHRISTIAN', 'OTHER'],
  sourceOptions: ['NEWSPAPER', 'WEBSITE', 'WALKIN', 'FRIEND', 'RELATIVE'],
  paymentOptions: [
    'CC',
    'ANDROID_APP',
    'DEBIT_CARD',
    'POS',
    'MOBILE_WALLET',
    'BANK_DEPOSIT',
    'CHEQUE',
    'CASH',
    'ONLINE',
    'MOBILE',
    'ECS',
  ],
  followUpStatusOptions: [
    'NOT SURE',
    'REGISTERED',
    'OLD STUDENT',
    'NOT INTERESTED',
    'ADMISSION APPLIED',
    'INTERESTED',
    'FORM FILLED',
    'SWITCHED OFF',
    'READY FOR ADMISSION',
    'NOT RESPONDING',
    'PENDING',
    'WILL DISCUSS',
  ],

  planOptions: [
    { title: 'Basic', value: 'basic' },
    { title: 'Company', value: 'company' },
    { title: 'Enterprise', value: 'enterprise' },
    { title: 'Standard', value: 'standard' },
  ],

  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  paymentTerms: [
    'Check & online payments are subject to realization',
    'If check is dis-hounered a fine of INR 1000/- will be charged',
    'Fees cycle due dates are 15th April,15 may, 15 Jun, 15 July, 15 August, 15 September, 15 October, 15 November, 15 December, 15 January , 15 Feburary, 15 March',
    'Late fee of INR 20/- will be charged beyond the due date ',
    'Fees once paid will not be refunded or adjusted',
  ],
  feeTypes: ['CLASS', 'STUDENT', 'ON_DEMAND'],
  feeFrequencies: ['ANNUALLY', 'MONTHLY', 'BI_MONTHLY', 'QUARTERLY', 'HALF_YEARLY', 'ONE_TIME'],
  examCategoryTypes: ['ACADEMIC', 'NONACADEMIC'],
}
