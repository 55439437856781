import { api, endpoint } from '@/plugins/axios'

const getDiscountGroups = async () => {
  const institutionId = localStorage.getItem('institutionId')
  const res = await api.get(endpoint.DISCOUNT_GROUP, { params: { institutionId } })

  return res
}
const createDiscountGroup = async payload => {
  const res = await api.post(endpoint.DISCOUNT_GROUP, payload)

  return res
}
const updateDiscountGroup = async payload => {
  const res = await api.put(endpoint.DISCOUNT_GROUP, payload)

  return res
}

const getDiscountStudents = async ({ searchQuery }) => {
  const params = { searchTerm: searchQuery }
  const res = await api.get(endpoint.DISCOUNT_SEARCH_STUDENTS, { params })

  return res
}

const getDiscountGroupFeesList = async params => {
  const res = await api.get(endpoint.DISCOUNT_GROUP_FEES_LIST, { params })

  return res
}
const getDiscountGroupFees = async params => {
  const res = await api.get(endpoint.DISCOUNT_GROUP_FEES, { params })

  return res
}
const updateDiscountGroupFees = async payload => {
  const res = await api.put(endpoint.DISCOUNT_GROUP_FEES, payload)

  return res
}

const getStudentAssignedDiscount = async params => {
  const res = await api.get(endpoint.DISCOUNT_GROUP_STUDENTS, { params })

  return res
}

const applyDiscountToStudent = async payload => {
  const res = await api.post(endpoint.DISCOUNT_GROUP_STUDENT, payload)

  return res
}
const getIndividualDiscountList = async params => {
  const res = await api.get(endpoint.INDIVIDUAL_DISCOUNT_LIST, { params })

  return res
}
const getIndividualDiscounts = async params => {
  const res = await api.get(endpoint.INDIVIDUAL_DISCOUNT, { params })

  return res
}
const assignIndividualDiscount = async payload => {
  const res = await api.post(endpoint.INDIVIDUAL_DISCOUNT, payload)

  return res
}

export default {
  getDiscountGroups,
  createDiscountGroup,
  updateDiscountGroup,
  getDiscountStudents,
  getDiscountGroupFeesList,
  getDiscountGroupFees,
  updateDiscountGroupFees,
  getStudentAssignedDiscount,
  applyDiscountToStudent,
  getIndividualDiscountList,
  getIndividualDiscounts,
  assignIndividualDiscount,
}
