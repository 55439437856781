import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// function checks if route is protected or unprotected.
//  If protected and logged in, go to route. Otherwise redirect to login.
router.beforeEach((to, from, next) => {
  // navigation guards
  if (to.matched.some(record => record.meta.protected)) {
    if (localStorage.getItem('accessToken') == null) {
      next({ path: '/login', params: { nextUrl: to.fullPath } })
    } else {
      next()
    }
  } else if (to.matched.some(record => !record.meta.protected)) {
    if (localStorage.getItem('accessToken') == null) {
      next()
    } else if (to.fullPath.includes('login')) {
      next(from.fullPath)
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
