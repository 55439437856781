import { api } from '@/plugins/axios'

const getAllOnlineEnquiry = async () => {
  const res = await api.get('/api/v1/online/enquiry')

  return res
}

const getOnlineEnquiryById = async params => {
  const res = await api.get(`/api/v1/online/enquiry/${params}`)

  return res
}

const updateOnlineEnquiry = async payload => {
  const res = await api.put('/api/v1/online/enquiry', payload)

  return res
}
const getFollowups = async params => {
  const res = await api.get('/api/v1/enquiry/follow-ups', { params })

  return res
}
const getDayHomework = async params => {
  const res = await api.get('/api/v1/followup/day', { params })

  return res
}
const searchFollowups = async params => {
  const res = await api.get(`api/v1/followup/search?searchTerm=${params}`)

  return res
}
export default {
  getAllOnlineEnquiry,
  getOnlineEnquiryById,
  updateOnlineEnquiry,
  getFollowups,
  getDayHomework,
  searchFollowups,
}
