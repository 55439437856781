import { api, endpoint } from '@/plugins/axios'
import { useBatchStore } from '@/stores/batchStore'

const getClassesByBatch = async params => {
  const res = await api.get(`${endpoint.CLASS}/${params.batchId}`)

  return res
}

const getAllBatches = async () => {
  const res = await api.get(endpoint.BATCH)

  return res
}

const getAllStudents = async params => {
  const res = await api.get(endpoint.STUDENT_SEARCH, {
    params: {
      searchTerm: params?.searchQuery || '',
      searchField: params?.searchField || 'NAME',
    },
  })

  return res
}

const getAllStudentsInfo = async params => {
  const res = await api.get(endpoint.STUDENT_SEARCH_INFO, {
    params: {
      searchTerm: params?.searchQuery || '',
      searchField: params?.searchField || 'NAME',
    },
  })

  return res
}

const getAllStudentsByBatch = async batchId => {
  const batchStore = useBatchStore()
  const params = { batchId, runningBatchId: batchStore.currentBatch.id }
  const res = await api.get(endpoint.STUDENT_BATCH, { params })

  return res
}

/**
 * this function fetch students by batchId, classId & section name
 * @param {object} params - filter parameters
 * @returns
 */
const getStudentBatchProfile = async data => {
  const batchStore = useBatchStore()
  const params = { ...data, runningBatchId: batchStore.currentBatch.id }
  const res = await api.get(endpoint.STUDENT_BATCH_PROFILE, { params })

  return res
}

const getStudentRollBatchProfile = async params => {
  const res = await api.get(endpoint.STUDENT_BATCH_ROLL_PROFILE, { params })

  return res
}
const updateStudentRollNo = async payload => {
  const res = await api.put(endpoint.STUDENT_BATCH_ROLL_PROFILE, payload)

  return res
}

const getStudentsFacility = async params => {
  const res = await api.get(endpoint.STUDENT_BATCH_FACILITY, { params })

  return res
}
const updateStudentsFacility = async payload => {
  const res = await api.put(endpoint.STUDENT_BATCH_FACILITY, payload)

  return res
}
const getDeactivatedStudents = async params => {
  const res = await api.get(endpoint.STUDENT_BATCH_DEACTIVE, { params })

  return res
}
const getStudentStats = async params => {
  const res = await api.get(endpoint.STUDENT_CLASS_COUNT, { params })

  return res
}
const getStudentComments = async studentId => {
  const res = await api.get(endpoint.STUDENT_COMMENTS, { params: { studentId } })

  return res
}
const addStudentComment = async payload => {
  const res = await api.post(endpoint.STUDENT_COMMENTS, payload)

  return res
}
const deactivateStudent = async payload => {
  const res = await api.delete(endpoint.STUDENT, { data: payload })

  return res
}
const activateStudent = async payload => {
  const res = await api.put(endpoint.ACTIVATE_STUDENT, payload)

  return res
}

/**
 * this api is used to fetch next student Roll No and Admission No
 * @returns {object} next admission and roll no
 */
const getNextAdmAndRollNo = async params => {
  const res = await api.get(endpoint.STUDENT_ROLLNO_ADMNO, { params })

  return res
}

export default {
  getAllStudents,
  getAllStudentsInfo,
  getAllBatches,
  getClassesByBatch,
  getAllStudentsByBatch,
  getStudentBatchProfile,
  getStudentsFacility,
  updateStudentsFacility,
  getDeactivatedStudents,
  getStudentStats,
  deactivateStudent,
  activateStudent,
  getStudentComments,
  addStudentComment,
  getNextAdmAndRollNo,
  getStudentRollBatchProfile,
  updateStudentRollNo,
}
