<template>
  <v-file-input v-model="file" label="Select a file" accept="image/*" @change="onFileChange">
    <template v-slot:prepend>
      <v-icon>mdi-file-upload</v-icon>
    </template>
    <template v-slot:selection="{ text }">
      {{ text }}
    </template>
  </v-file-input>
</template>

<script>
export default {
  name: 'FileUploader',
  data() {
    return { file: null }
  },
  methods: {
    onFileChange(event) {
      //   this.file = event.target.files[0]
      this.uploadFile()
    },
    uploadFile() {
      // Add your code to upload the file here
    },
  },
}
</script>
