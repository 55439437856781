import { api, endpoint } from '@/plugins/axios'

// exam coscholastic crud api
const getAllCoScholasticRemarks = async params => {
  const res = await api.get(endpoint.EXAM_STUDENT_COSCHOLASTIC_REMARKS, { params })

  return res
}

const updateCoScholasticRemark = async payload => {
  const res = await api.post(endpoint.EXAM_STUDENT_COSCHOLASTIC_REMARKS, payload)

  return res
}

// exam attendance remarks crud api
const getAllAttendanceRemarks = async params => {
  const res = await api.get(endpoint.EXAM_STUDENT_ATTENDANCE_REMARKS, { params })

  return res
}

const updateAttendanceRemark = async payload => {
  const res = await api.post(endpoint.EXAM_STUDENT_ATTENDANCE_REMARKS, payload)

  return res
}

export default {
  getAllCoScholasticRemarks,
  updateCoScholasticRemark,

  getAllAttendanceRemarks,
  updateAttendanceRemark,

}
