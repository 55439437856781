export default {
  path: '/',
  component: () => import('@/layouts/LayoutContentHorizontalNav.vue'),
  meta: { protected: true },
  children: [
    {
      path: 'manage/subjects/ViewSubjectByStaff',
      name: 'ViewSubjectByStaff',
      component: () => import('@/views/subjects/ViewSubjectByStaff.vue'),
    },
    {
      path: 'manage/subjects/AssignedSubjectStaff',
      name: 'AssignedSubjectToStaff',
      component: () => import('@/views/subjects/AssignedSubjectStaff.vue'),
    },
    {
      path: 'manage/subjects/StaffCoscholastic',
      name: 'StaffCoscholastic',
      component: () => import('@/views/subjects/StaffCoscholastic.vue'),
    },
    {
      path: 'manage/subjects/ViewAssignedCoscholastic',
      name: 'ViewAssignedCoscholastic',
      component: () => import('@/views/subjects/ViewAssignedCoscholastic.vue'),
    },
    {
      path: 'manage/subjects/overview',
      name: 'managesubjectsoverview',
      component: () => import('@/views/subjects/Overview.vue'),
    },
    {
      path: 'manage/subjects/scholastic',
      name: 'AssignedSubjectsToClass',
      component: () => import('@/views/subjects/AssignedSubjectsClass.vue'),
    },
  ],
}
