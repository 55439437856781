// @ts-check
import dayjs from 'dayjs'
import objectSupport from 'dayjs/plugin/objectSupport'
import relativeTime from 'dayjs/plugin/relativeTime'
import arraySupport from 'dayjs/plugin/arraySupport'
import Vue from 'vue'

// dayjs extends
dayjs.extend(arraySupport)
dayjs.extend(relativeTime)
dayjs.extend(objectSupport)

export const toISOString = (date = dayjs()) => dayjs(date).toISOString()

/**
 * Get the formatted date according to the string of tokens passed in.
 * By default date format is `YYYY-MM-DD`
 * @param {*} d A date string
 * @param {string} format formating string
 * @returns formated date
 */
export const date = (d, format = 'YYYY-MM-DD') => dayjs(d).format(format)

// calculate age from dob
export const getAge = dob => {
  const age = dayjs(dob).fromNow(true)

  return !age.includes('NaN') ? age : ''
}

/**
 * calculate duration between 2 dates
 * @param {object|array|string} fromDate duration startDate
 * @param {object|array|string} toDate duration startDate
 * @returns {string} duration between 2 date's
 */
export const getDuration = (fromDate, toDate) => {
  const duration = dayjs(toDate).from(fromDate, true)

  return !duration.includes('NaN') ? duration : ''
}

/**
 * This function checks is date before current time or not
 * @param {string} targetDate
 * @returns {boolean}
 */
export const isBefore = targetDate => {
  const currentDate = dayjs()

  return currentDate.isBefore(dayjs(targetDate))
}

/**
 * Generates an array of formatted month strings within a specified date range.
 *
 * @param {string} startDate - The start date in 'YYYY-MM-DD' format.
 * @param {string} endDate - The end date in 'YYYY-MM-DD' format.
 * @param {string} [format='MMMM-YYYY'] - The format for each month string.
 * @returns {string[]} An array of formatted month strings.
 */
export const generateMonthRange = (startDate, endDate, format = 'MMM-YYYY') => {
  const monthsList = []
  let currentDate = dayjs(startDate)
  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'month')) {
    monthsList.push(currentDate.format(format))
    currentDate = currentDate.add(1, 'month')
  }

  return monthsList
}

// Vue.prototype.$toISOString = toISOString
// Vue.prototype.$getAge = getAge
Vue.prototype.$getDate = date
Vue.prototype.$getDuration = getDuration
Vue.prototype.$dayjs = dayjs

export default { getDate: date, getDuration, getAge, toISOString, isBefore, generateMonthRange }
