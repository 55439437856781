import { api, endpoint } from '@/plugins/axios'

/*
  * Routes api's
 =================================================
 */
const getRoutebyInstitutionId = async institutionId => {
  const res = await api.get(`/api/v1/transport/routes?instId=${institutionId}`)

  return res
}

const createRoute = async payload => {
  const res = await api.post('/api/v1/transport/routes', payload)

  return res
}
const updateRoute = async payload => {
  const res = await api.put('/api/v1/transport/routes', payload)

  return res
}

/*
  * Pickup Point api's
 =================================================
 */
const getPickpoints = async () => {
  const instId = localStorage.getItem('institutionId')
  const res = await api.get('/api/v1/transport/pickup-point', { params: { instId } })

  return res
}

const createPickpoint = async payload => {
  const res = await api.post('/api/v1/transport/pickup-point', payload)

  return res
}

const updatePickpoint = async payload => {
  const res = await api.put('/api/v1/transport/pickup-point', payload)

  return res
}
const deletePickupPoint = async pickupId => {
  const res = await api.delete('/api/v1/transport/pickup-point', { params: { pickupId } })

  return res
}

/*
  * vehicle api's
 =================================================
 */

const addVehicle = async payload => {
  const res = await api.post('/api/v1/transport/vehicle', payload)

  return res
}
const updateVehicle = async payload => {
  const res = await api.put('/api/v1/transport/vehicle', payload)

  return res
}
const getVehicles = async params => {
  // const instId = localStorage.getItem('institutionId')
  const res = await api.get('/api/v1/transport/vehicle', { params })

  return res
}

const getVehicaldocument = async id => {
  const res = await api.get(`api/v1/documents/${id}`)

  return res
}

const addNewvehicaldocument = async payload => {
  const res = await api.post('/api/v1/documents', payload)

  return res
}

/*
  * drivers api's
 =================================================
 */
const getDrivers = async () => {
  const instId = localStorage.getItem('institutionId')
  const res = await api.get(endpoint.TRANSPORT_DRIVERS, { params: { instId } })

  return res
}
const addDriver = async payload => {
  const res = await api.post(endpoint.TRANSPORT_DRIVER, payload)

  return res
}
const updateDriver = async payload => {
  const res = await api.put(endpoint.TRANSPORT_DRIVER, payload)

  return res
}
const deleteDriver = async driverId => {
  const res = await api.put(endpoint.TRANSPORT_DRIVER, { params: { driverId } })

  return res
}

/*
  * other api's
 =================================================
 */
const getStaff = async id => {
  const res = await api.get(`/api/v1/staff/institution?institutionId=${id}`)

  return res
}

const addAsignStudent = async payload => {
  const res = await api.post('/api/v1/transport/student', payload)

  return res
}
const assignStaff = async payload => {
  const res = await api.post('/api/v1/transport/staff', payload)

  return res
}

const transportStd = async payload => {
  const res = await api.get('/api/v1/transport/student', { params: payload })

  return res
}
const removeStudent = async id => {
  const res = await api.patch(`/api/v1/transport/student?id=${id}`)

  return res
}
const getStdbyadmission = async id => {
  const res = await api.get(`${endpoint.STUDENT_ADMISSION}/${id}`)

  return res
}

export default {
  // routes api's
  getRoutebyInstitutionId,
  createRoute,
  updateRoute,

  // vehicle api's
  addVehicle,
  updateVehicle,
  getVehicles,
  getVehicaldocument,
  addNewvehicaldocument,

  // pickup point api's
  getPickpoints,
  createPickpoint,
  updatePickpoint,
  deletePickupPoint,

  // driver api's
  getDrivers,
  addDriver,
  updateDriver,
  deleteDriver,

  // other api's
  addAsignStudent,
  getStaff,
  transportStd,
  assignStaff,
  getStdbyadmission,
  removeStudent,
}
