import PDF from 'jspdf'

const prepareData = (rows, headers) => {
  const rowsData = []

  if (Array.isArray(rows) && rows.length > 0) {
    rows.forEach(row => {
      const obj = {}

      headers.forEach(header => {
        let value = row[header.value] || ''

        // Handle nested properties
        const nestedProps = header.value.split('.')
        if (nestedProps.length > 1) {
          const [prop1, ...props] = nestedProps
          value = props.reduce((acc, prop) => (acc && typeof acc === 'object' ? acc[prop] : ''), row[prop1])
        }

        // Handle number type properties
        if (typeof value === 'number') {
          value = value.toString()
        }

        obj[header.value] = value
      })

      rowsData.push(obj)
    })
  }

  return rowsData
}

const createHeaders = headers => {
  const result = []

  headers.forEach(i => {
    result.push({
      id: i.value,
      name: i.value,
      prompt: i.value,
      width: 55.7,
      padding: 0,
    })
  })

  return result
}

const exportToPdf = (headers, data, pdfName = 'table') => {
  const columns = createHeaders(headers)
  const rows = prepareData(data, headers)

  const doc = new PDF({ putOnlyUsedFonts: true, orientation: 'landscape' })

  if (rows.length > 0) {
    doc.table(2, 1, rows, columns, { autoSize: true })
  }

  doc.save(`${pdfName}.pdf`)
}

export default exportToPdf
