import { api, endpoint } from '@/plugins/axios'

// exam category crud api
const getAllCategories = async () => {
  const res = await api.get(endpoint.EXAM_CATEGORY)

  return res
}

const createCategory = async payload => {
  const res = await api.post(endpoint.EXAM_CATEGORY, payload)

  return res
}

const updateCategory = async payload => {
  const res = await api.put(endpoint.EXAM_CATEGORY, payload)

  return res
}

const deleteCategory = async categoryId => {
  const res = await api.delete(endpoint.EXAM_CATEGORY, { params: { categoryId } })

  return res
}

// Terms crud api
const getAllTerms = async () => {
  const institutionId = localStorage.getItem('institutionId')
  const res = await api.get(endpoint.EXAM_TERM_ALL, { params: { institutionId } })

  return res
}

const createTerm = async payload => {
  const res = await api.post(endpoint.EXAM_TERM, payload)

  return res
}

const updateTerm = async payload => {
  const res = await api.put(endpoint.EXAM_TERM, payload)

  return res
}

const deleteTerm = async termId => {
  const res = await api.delete(`${endpoint.EXAM_TERM}/${termId}`)

  return res
}

// exam crud api
const getAllExams = async () => {
  const institutionId = localStorage.getItem('institutionId')
  const res = await api.get(endpoint.EXAM_ALL, { params: { institutionId } })

  return res
}

const createExam = async payload => {
  const res = await api.post(endpoint.EXAM, payload)

  return res
}

const updateExam = async payload => {
  const res = await api.put(endpoint.EXAM, payload)

  return res
}

const deleteExam = async examId => {
  const res = await api.delete(`${endpoint.EXAM}/${examId}`)

  return res
}

// exam activity api
const getAllActivities = async () => {
  const institutionId = localStorage.getItem('institutionId')
  const res = await api.get(endpoint.EXAM_ACTIVITY_ALL, { params: { institutionId } })

  return res
}

const createActivity = async payload => {
  const res = await api.post(endpoint.EXAM_ACTIVITY, payload)

  return res
}

const updateActivity = async payload => {
  const res = await api.put(endpoint.EXAM_ACTIVITY, payload)

  return res
}

const deleteActivity = async activityId => {
  const res = await api.delete(`${endpoint.EXAM_ACTIVITY}/${activityId}`)

  return res
}

// exam setting manageDiscriptiveInd

const getAllChildSkills = async () => {
  const institutionId = localStorage.getItem('institutionId')
  const res = await api.get(`${endpoint.GET_CHILDSKIL}`, { params: { institutionId } })

  return res
}
const getAllDescriptiveIndicators = async () => {
  const institutionId = localStorage.getItem('institutionId')
  const res = await api.get(`${endpoint.GET_DESCRIPTIVEINDI}`, { params: { institutionId } })

  return res
}
const createDescriptiveIndicators = async payload => {
  const res = await api.post(endpoint.CREATE_DESCRIPTIVEINDI, payload)

  return res
}
const updateDescriptiveIndicators = async payload => {
  const res = await api.put(endpoint.CREATE_DESCRIPTIVEINDI, payload)

  return res
}
const deleteDescriptiveIndicators = async id => {
  const res = await api.delete(`${endpoint.CREATE_DESCRIPTIVEINDI}/${id}`)

  return res
}

//! Marks Entry
// Subject Mark entry api

const getSubjectMarks = async params => {
  const res = await api.get(`${endpoint.STUDENT_SUBJECT_MARKS}`, { params })

  return res
}

const postSubjectMarks = async payload => {
  const res = await api.post(endpoint.STUDENT_SUBJECT_MARKS, payload)

  return res
}

// co-scholastic Mark entry api

const getCoscholasticMarks = async params => {
  const res = await api.get(`${endpoint.STUDENT_CO_SCHOLASTIC_MARKS}`, { params })

  return res
}

const postCoscholasticMarks = async payload => {
  const res = await api.post(endpoint.STUDENT_CO_SCHOLASTIC_MARKS, payload)

  return res
}

const freezeMarks = async params => {
  const res = await api.get(`${endpoint.FREEZE_MARKS}`, { params })

  return res
}

const freezeMarksCheck = async params => {
  const res = await api.get(`${endpoint.FREEZE_MARKS_CHECK}`, { params })

  return res
}

export default {
  // categories crud
  getAllCategories,
  createCategory,
  updateCategory,
  deleteCategory,

  // Terms crud
  getAllTerms,
  createTerm,
  updateTerm,
  deleteTerm,

  // Terms crud
  getAllExams,
  createExam,
  updateExam,
  deleteExam,

  // activity crud
  getAllActivities,
  createActivity,
  updateActivity,
  deleteActivity,

  // exam setting
  getAllChildSkills,
  getAllDescriptiveIndicators,
  createDescriptiveIndicators,
  updateDescriptiveIndicators,
  deleteDescriptiveIndicators,

  freezeMarks,
  freezeMarksCheck,

  // subject marks entry
  getSubjectMarks,
  postSubjectMarks,

  // co-scholastic
  getCoscholasticMarks,
  postCoscholasticMarks,
}
