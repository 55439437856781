import { api, endpoint } from '@/plugins/axios'
import { defineStore } from 'pinia'

export const useBatchStore = defineStore('batch', {
  state: () => ({ batchList: [] }),
  getters: {
    getBatchList: state => state.batchList,
    currentBatch: state => state.batchList?.find(batch => batch.running),
    currentDate: state => {
      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = String(currentDate.getMonth() + 1).padStart(2, '0')
      const day = String(currentDate.getDate()).padStart(2, '0')

      return `${year}-${month}-${day}`
    },
  },
  actions: {
    async getBatches() {
      const institutionId = localStorage.getItem('institutionId')
      const res = await api.get(`${endpoint.BATCH}/${institutionId}/institution`)
      if (res?.responseObject) {
        this.batchList = res.responseObject.filter(batch => batch.active)
      }

      return this.batchList
    },
    async createBatch(payload) {
      const res = await api.post(endpoint.BATCH, payload)

      return res
    },
    async updateBatch(payload) {
      const res = await api.put(endpoint.BATCH, payload)

      return res
    },
    async deleteBatch(batchId) {
      const res = await api.delete(`${endpoint.BATCH}/${batchId}`)

      return res
    },
    async getClassesByBatch(batchId) {
      const res = await api.get(`${endpoint.CLASS}/${batchId}`)

      return res
    },

  },
  persist: true,
})
